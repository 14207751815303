import api from "../../api/api";

export const CreateingTicket = async (data) => {
    const response = await api.request({
        url: `/v1/ticket/`,
        method: "POST",
        data: data
    });
    return response;
};

export const getAllTicketRooms = async (filter, search) => {

    let newFilter = '';
    if (filter !== 'allManual') {
        newFilter = filter
    }
    const response = await api.request({
        url: `/v1/ticket/rooms?filter=${newFilter}&search=${search}`,
        method: "GET",
    });
    return response;
};

export const getTicketDetails = async (ticketId) => {
    const response = await api.request({
        url: `/v1/ticket/${ticketId}`,
        method: "GET",
    });
    return response;
};

export const approvedTicket = async (ticketId) => {
    const response = await api.request({
        url: `/v1/ticket/approve/${ticketId}`,
        method: "PATCH",
    });
    return response;
};

export const disapprovedTicket = async (ticketId) => {
    const response = await api.request({
        url: `/v1/ticket/disapprove/${ticketId}`,
        method: "PATCH",
    });
    return response;
};

export const getAllEmployees = async () => {
    const response = await api.request({
        url: `/v1/admin/alluser?role=Employee&fields=_id,fullname,email,photo,role`,
        method: "GET",
    });
    return response;
};

export const getAllAdmins = async () => {
    const response = await api.request({
        url: `/v1/admin/alluser?role=Admin&fields=_id,fullname,email,photo,role`,
        method: "GET",
    });
    return response;
};

export const AddMemberToTicket = async (ticketId, data) => {
    const response = await api.request({
        url: `/v1/ticket/adduser/${ticketId}`,
        method: "PATCH",
        data: data
    });
    return response;
};

export const getChatList = async (ticketId, date) => {
    const response = await api.request({
        url: `/v1/chat/${ticketId}?limit=10&date=${date || ""}`,
        method: "GET",
    });
    return response;
};

export const upDateTicketDetails = async (ticketId, data) => {
    const response = await api.request({
        url: `/v1/ticket/${ticketId}`,
        method: "PATCH",
        data: data
    });
    return response;
};

export const closeTicketStatus = async (ticketId, cardTag, other) => {
    let data = {
        card_tag: cardTag,
        other: other
    }
    const response = await api.request({
        url: `/v1/ticket/close/${ticketId}`,
        method: "PATCH",
        data: data
    });
    return response;
};

export const archieveOrUnArchieveTicket = async (ticketId, archieve) => {
    let data = {
        archieve: archieve
    }
    const response = await api.request({
        url: `/v1/ticket/archieve/${ticketId}`,
        method: "PATCH",
        data: data
    });
    return response;
};

export const getAllTicketCount = async () => {
    const response = await api.request({
        url: `/v1/ticket/allticketcount`,
        method: "GET",
    });
    return response;
};

export const sendFileInChat = async (data) => {

    const response = await api.request({
        url: `/v1/chat`,
        method: "POST",
        data
    });
    return response;
};