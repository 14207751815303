import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, unstable_HistoryRouter, useLocation } from "react-router-dom";

export default function UserHeader() {
  const {t} = useTranslation();
  const [currentSection, setCurrentSection] = useState("home");
  const name = localStorage.getItem("billboardName");
  const location = useLocation();
  // const history = unstable_HistoryRouter();

  // const handleUploadAdClick = () => {
  //   // Open QR code scanner
  //   const scannerWindow = window.open("url_of_your_qr_scanner", "_blank");

  //   // Check if the scanner window was opened successfully
  //   if (scannerWindow) {
  //     // Set a timeout to redirect to the QR code URL after scanning
  //     // setTimeout(() => {
  //     //   scannerWindow.close(); // Close the scanner window
  //     //   history.push("url_of_your_qr_code_url"); // Redirect to the QR code URL
  //     // }, 5000); // Adjust the delay as needed
  //   } else {
  //     // Handle if the scanner window couldn't be opened
  //     console.error("Failed to open scanner window");
  //   }
  // };

  useEffect(() => {
    if (location.pathname.includes("/user/dashboard")) {
      setCurrentSection("home");
    }
    if (location.pathname.includes(`/user/${name}/upload-ad`)) {
      setCurrentSection("uploadAd");
    } else if (location.pathname.includes("/user/profile")) {
      setCurrentSection("profile");
    }
  }, []);
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "0px",
        left: "0px",
        right: "0px",
        py: 2,
        px: 3,
        zIndex: 10,
        borderRadius: "16px 16px 0px 0px",
        border: "1px solid rgba(255, 255, 255, 0.15)",
        // background:
        //   "linear-gradient(91deg, rgb(255 255 255 / 14%) 0%, rgba(255, 255, 255, 0.03) 100%)",
        background: "#312e2e",
        maxWidth: "510px",
        margin: "0px auto",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        component={"ul"}
        spacing={2}
        sx={{
          p: 0,
          mb: 0,
          "& li a": {
            color: "#DADADA",
            textDecoration: "none",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: `"Poppins", sans-serif !important`,
            "&.menuActive": {
              color: "#FBC515",
            },
            "&:hover": {
              color: "#FBC515",
            },
          },
          "& span": {
            marginLeft: "10px",
          },
        }}
      >
        <Stack direction={"row"} spacing={1} component={"li"}>
          <Link
            to={"/user/dashboard"}
            className={currentSection === "home" ? "menuActive" : null}
            onClick={() => setCurrentSection("home")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.3861 1.21065C11.7472 0.929784 12.2528 0.929784 12.6139 1.21065L21.6139 8.21065C21.8575 8.4001 22 8.69141 22 9V20C22 20.7957 21.6839 21.5587 21.1213 22.1213C20.5587 22.6839 19.7957 23 19 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7957 2 20V9C2 8.69141 2.14247 8.4001 2.38606 8.21065L11.3861 1.21065ZM4 9.48908V20C4 20.2652 4.10536 20.5196 4.29289 20.7071C4.48043 20.8946 4.73478 21 5 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20V9.48908L12 3.26686L4 9.48908Z"
                fill="currentColor"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 12C8 11.4477 8.44772 11 9 11H15C15.5523 11 16 11.4477 16 12V22C16 22.5523 15.5523 23 15 23C14.4477 23 14 22.5523 14 22V13H10V22C10 22.5523 9.55228 23 9 23C8.44772 23 8 22.5523 8 22V12Z"
                fill="currentColor"
              />
            </svg>
            <Box component={"span"}>{t("home")}</Box>
          </Link>
        </Stack>
        <Stack direction={"row"} spacing={1} component={"li"}>
          <Link
            to={`/user/dashboard/scanner`}
            className={currentSection === "uploadAd" ? "menuActive" : null}
            // onClick={handleUploadAdClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M9 17V11L7 13"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9 11L11 13"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22 10H18C15 10 14 9 14 6V2L22 10Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Box component={"span"}>{t("upload_ad")}</Box>
          </Link>
        </Stack>
        <Stack direction={"row"} spacing={1} component={"li"}>
          <Link
            to={"/user/profile"}
            className={currentSection === "profile" ? "menuActive" : null}
            onClick={() => setCurrentSection("profile")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.71997 11.28 8.71997 9.50998C8.71997 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Box component={"span"}>{t("my_profile")}</Box>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}
