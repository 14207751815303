import { useCallback } from "react";
import React, { useState } from "react";
import { GetAllSummary, LastScan } from "../../api/auth";
import moment from "moment/moment";
import { useContext } from "react";
import { PermissionContext } from "./user.provider";
import { USER_ROLE } from "../settings/invoice/awaiting-approval/invoice.data";

function median(values) {
  if (values.length === 0) return 0;

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

const MainLayout = ({ ChildElement }) => {
  const [lastScanTime, setLastScanTime] = useState(0);
  const [rushtime, setRushtime] = useState(0);
  const { userData } = useContext(PermissionContext);

  const loadData = useCallback(() => {
    GetAllSummary().then(({ data }) => {
      if (data && data.status === "success") {
        let new_data = data.data.documents;

        if (userData.role === USER_ROLE.Client) {
          const filtered_data = userData.billboards.map((el) => new_data[el]);
          const rushTimes = filtered_data.reduce((acc, curr) => {
            if (curr?.rush_hour) {
              return [...acc, parseInt(curr?.rush_hour) || 0];
            }
            return acc;
          }, []);
          setRushtime(Math.round(median(rushTimes)));
        } else {
          const rushTimes = Object.values(data).reduce((acc, curr) => {
            if (curr?.rush_hour) {
              return [...acc, parseInt(curr?.rush_hour) || 0];
            }
            return acc;
          }, []);
          setRushtime(Math.round(median(rushTimes)));
        }
      }
    });

    // Set Last Scan Time Sart
    LastScan().then(({ data }) => {
      if (data) {
        setLastScanTime(moment(data.lastScan).format("DD MMM YY, HH:mm:ss "));
      }
    });
  }, []);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <ChildElement
      lastScanTime={lastScanTime}
      rushtime={rushtime}
      timedefine={moment().format("YYYY/MM/DD, h:mm:ss ")}
    />
  );
};

export default MainLayout;
