import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowLeft from "../components/icon/arrow-left.svg";
import CustomButton from "../components/commanButton";
import { useTranslation } from "react-i18next";

export default function AdvertisingPolicy() {
  const { t } = useTranslation();
  const { Id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        sx={{ mb: 3 }}
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      >
        <IconButton
          sx={{
            background: "#fff",
            color: "#000",
            opacity: 1,
            "&:hover": { color: "#000", background: "#fff" },
          }}
          onClick={() => navigate(`/user/${Id}/ads`)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Box
          className="backArrow"
          sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
          component={"span"}
        >
          {t("ad_policy")}
        </Box>
      </Stack>
      <Stack
        dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        direction={"column"}
        spacing={2}
        sx={{
          "& .MuiTypography-h4": {
            color: "#FBC515",
            fontSize: "20px",
            fontWeight: "500",
            fontFamily: `"Poppins", sans-serif `,
            mb: 1.5,
          },
          "& .MuiTypography-body1": {
            fontSize: "16px",
            color: "#EAEAEA",
            fontWeight: 400,
            fontFamily: `"Poppins", sans-serif `,
          },
        }}
      >
        <Box>
          <Typography variant="h4">{t("ad_content_approval")}</Typography>
          <Typography variant="body1">
            {t("ad_content_approval_body")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4">{t("ad_video_requirements")}</Typography>
          <Typography variant="body1">
            {t("ad_video_requirements_body")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4">{t("ad_campaign_cost")}</Typography>
          <Typography variant="body1">{t("ad_campaign_cost_body")}</Typography>
        </Box>
        <Box>
          <Typography variant="h4">{t("ad_cost_calculation")}</Typography>
          <Typography variant="body1">
            {t("ad_cost_calculation_body")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h4">{t("ad_additional_note")}</Typography>
          <Typography variant="body1">
            {t("ad_additional_note_body")}
          </Typography>
        </Box>
        <Link to={"/"}>
          <CustomButton
            size="15px"
            btnText={t("accept_continue")}
            // icon={<img src={ArrowLeft} alt="" />}
            onClick={() => localStorage.setItem("policy", "accepted")}
          />
        </Link>
      </Stack>
    </Box>
  );
}
