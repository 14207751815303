import React from "react";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

const CustomToolbar = ({ onClickRaw }) => (
  <div id="toolbar1">
    <span className="ql-formats">
      <select className="ql-size">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select>
    </span>

    <span className="ql-formats">
      <select className="ql-header">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      {/* <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" /> */}
      <button className="ql-blockquote" />
      {/* <button className="ql-direction" /> */}
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      {/* 
        <button className="ql-video" />*/}
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button onClick={onClickRaw} className="ql-code-block" />
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

const AdminNoteEditor = ({ content, handleChangeContent }) => {
  const [raw_html, setRawHTML] = useState("");
  const [show_raw, setShowRaw] = useState(false);
  const handleClickShowRaw = () => {
    setShowRaw(!show_raw);
    if (show_raw) handleChangeContent(raw_html);
    else setRawHTML(content);
  };

  useEffect(()=>{
    setRawHTML(content)
  },[content])

  return (
    <div className={show_raw ? "showRaw" : ""}>
      <div className={"text-editor"}>
        <CustomToolbar onClickRaw={handleClickShowRaw} />
        <ReactQuill
          onChange={(html) => handleChangeContent(html)}
          placeholder="content here"
          modules={{
            toolbar: {
              container: "#toolbar1",
              handlers: {},
            },
          }}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "color",
          ]}
          value={content}
          theme={"snow"}
        />
        <textarea
          className={"raw-editor"}
          onChange={(e) => handleChangeContent(e.target.value)}
          value={content}
        ></textarea>
      </div>
    </div>
  );
};

export default AdminNoteEditor;
