import axios from "axios";

const backendHost = process.env.REACT_APP_BACKEND_HOST

export const baseURL = `${backendHost}/api`;
export const baseURLImg = `${backendHost}/media`;
export const SocketBaseUrl = `${backendHost}/`;

const axiosInstance = () => {
  const instance = axios.create({
    baseURL: baseURL,
  });
  return instance;
};

export const parseResponse = (response) => {
  try {
    const data = JSON.parse(response);
    if (data.errors) {
      return {
        remote: "failure",
        error: {
          errors: data.errors,
        },
      };
    }
    return {
      remote: "success",
      data: data,
    };
  } catch (error) {
    return {
      remote: "failure",
      error: {
        errors: response,
      },
    };
  }
};

export const request = async (config) => {
  try {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const instance = axiosInstance();
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    const response = await instance.request({
      ...config,
      transformResponse: (res) => {
        const resp = parseResponse(res);
        return resp.remote === "success" ? resp.data : resp;
      },
    });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    if (error) {
      if (error.response) {
        const axiosError = error;
        if (axiosError.response.data.error) {
          let errorMessage = axiosError.response.data.error.errors;

          return {
            remote: "failure",
            errors: {
              status: axiosError?.response?.status,
              errors: errorMessage,
            },
          };
        }
      }
    } else {
      const axiosError = error;
      let errorMessage = axiosError.message;
      if (errorMessage === "Network Error") {
        errorMessage = "No internet connection";
      }
      return {
        remote: "failure",
        errors: {
          errors: errorMessage,
        },
      };
    }
    return error;
  }
};

export const requestWithFormData = async (config) => {
  try {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const instance = axiosInstance();
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    config.headers["Authorization"] = `Bearer ${token}`;
    const response = await instance.request({
      ...config,
      transformResponse: (res) => {
        const resp = parseResponse(res);
        return resp.remote === "success" ? resp.data : resp;
      },
    });
    return {
      remote: "success",
      data: response.data,
    };
  } catch (error) {
    if (error) {
      if (error.response) {
        const axiosError = error;
        if (axiosError.response.data.error) {
          let errorMessage = axiosError.response.data.error.errors;

          return {
            remote: "failure",
            errors: {
              status: axiosError?.response?.status,
              errors: errorMessage,
            },
          };
        }
      }
    } else {
      const axiosError = error;
      let errorMessage = axiosError.message;
      if (errorMessage === "Network Error") {
        errorMessage = "No internet connection";
      }
      return {
        remote: "failure",
        errors: {
          errors: errorMessage,
        },
      };
    }
    return error;
  }
};

export const AuthClient = () => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const instance = axiosInstance();
  instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  return instance;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  request,
  parseResponse,
};
