import * as Yup from "yup";

export const createInVoiceSchema = Yup.object({
    customer: Yup.string().optional(),
    employee: Yup.string().optional(),
    billboard: Yup.string().required(),
    discount_percentage: Yup.boolean().required(),
    invoice_due_date: Yup.date().required(),
    payment_mode: Yup.string().required(),
    discount_type: Yup.string().required(),
    discount: Yup.number().optional(),
    adjustment: Yup.number().optional(),
    items: Yup.array().min(1).required(),
    status: Yup.string().required(),
    admin_note: Yup.string().optional(),
    terms_and_conditions: Yup.string().optional(),
    type: Yup.string().optional()
});

export const createIncomeInVoiceSchema = Yup.object({
    customer: Yup.string().required(),
    billboard: Yup.string().required(),
    discount_percentage: Yup.boolean().required(),
    invoice_due_date: Yup.date().required(),
    payment_mode: Yup.string().required(),
    discount_type: Yup.string().required(),
    discount: Yup.number().optional(),
    adjustment: Yup.number().optional(),
    items: Yup.array().min(1).required(),
    status: Yup.string().required(),
    admin_note: Yup.string().optional(),
    terms_and_conditions: Yup.string().optional(),
    type: Yup.string().optional()
});


