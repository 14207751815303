import { Outlet } from "react-router-dom";
import { Suspense, lazy } from "react";
import adsRoute, { AdsLayout } from "./component/ads/routes.helper";
import settingsRoute from "./component/settings/routes.helper";
import NotFound from "./utils/not-found.page";
import PublicBillboardDetails from "./component/billboard/public/public-billboard";
import SettingLayout from "./component/settings/setting.layout";
import Dashboard_bg from "./images/dashboard_bg.png";
import { Box, Container } from "@mui/material";
import SplashHome from "./user";
import "./user/userstyle.css";
import UserLogin from "./user/login";
import Codeverification from "./user/code-verification";
import UserAds from "./user/ads";
import Information from "./user/information";
import AdvertisingPolicy from "./user/advertising-policy";
import UploadAds from "./user/upload-ads";
import UserDashboard from "./user/dashboard";
import Profile from "./user/profile";
import ManageProfile from "./user/profile/manage-profile";
import QRScanner from "./user/dashboard/qr-scanner";
import LanguageSelector from "./user/components/languageSelector";
const ForgotPassword = lazy(() => import("./auth/ForgotPassword"));
const Login = lazy(() => import("./auth/Login"));
const ResetPassword = lazy(() => import("./auth/ResetPassword"));
const Homeview = lazy(() => import("./component/home"));
const StatisticsView = lazy(() => import("./component/home/StatisticsView"));
const BillboardDetails = lazy(() =>
  import("./component/billboard/detail/billboard-detail.page")
);
const SelectBillboard = lazy(() =>
  import("./component/billboard/detail/SelectBillboard")
);
const BillboardEdit = lazy(() =>
  import("./component/billboard/edit/billboard-edit.page")
);
const AutomationCheck = lazy(() =>
  import("./component/automation-check/automation-check.component")
);
const TicketComponent = lazy(() => import("./component/ticket/ticket.page"));
const MainLayout = lazy(() => import("./component/layout/main"));
const UserProvider = lazy(() => import("./component/layout/user.provider"));
const ProfileView = lazy(() => import("./component/profile"));
const ProfileEdit = lazy(() => import("./component/profile/ProfileEdit"));

const routes = [
  {
    path: "*",
    element: <NotFound />,
  },
  // {
  //   path: "/",
  //   element: (
  //     <Suspense fallback="Loading...">
  //       <UserProvider>
  //         <Outlet />
  //       </UserProvider>
  //     </Suspense>
  //   ),
  //   children: [
  //     {
  //       path: "",
  //       element: <MainLayout ChildElement={Homeview} />,
  //     },
  //     {
  //       path: "ticket",
  //       element: <TicketComponent />,
  //     },
  //     {
  //       path: "statistics",
  //       element: <StatisticsView />,
  //     },
  //     {
  //       path: "billboards/:name",
  //       element: <MainLayout ChildElement={BillboardDetails} />,
  //     },
  //     {
  //       path: "billboard-details",
  //       element: <SelectBillboard />,
  //     },
  //     {
  //       path: "billboard-setting",
  //       element: <MainLayout ChildElement={BillboardEdit} />,
  //     },
  //     {
  //       path: "automation-check",
  //       element: <AutomationCheck />,
  //     },
  //     {
  //       path: "automation-check/:billboard",
  //       element: <AutomationCheck />,
  //     },
  //     {
  //       path: "billboards/:name/edit",
  //       element: <MainLayout ChildElement={BillboardEdit} />,
  //     },

  //     {
  //       path: "my-profile",
  //       element: <ProfileView />,
  //     },
  //     {
  //       path: "my-profile/edit/",
  //       element: <ProfileEdit />,
  //     },
  //     ...settingsRoute.map((route) => ({
  //       path: route.path,
  //       element: (
  //         <SettingLayout permission={route.permission}>
  //           {route.element}
  //         </SettingLayout>
  //       ),
  //     })),
  //     ...adsRoute.map((route) => ({
  //       path: route.path,
  //       element: (
  //         <AdsLayout permission={route.permission}>{route.element}</AdsLayout>
  //       ),
  //     })),
  //   ],
  // },
  // {
  //   path: "/auth",
  //   element: <Login />,
  // },
  // {
  //   path: "/forgot-password",
  //   element: <ForgotPassword />,
  // },
  // {
  //   path: "/reset-password/:id",
  //   element: <ResetPassword />,
  // },
  // {
  //   path: "/billboard-snap/:id",
  //   element: <PublicBillboardDetails />,
  // },
  {
    path: "/",
    element: (
      <Box
        sx={{
          py: 0,
          display: "flex",
          minHeight: "100vh",
          background: "#7d7d7d",
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            minHeight: "100%",
            "@media (min-width: 600px)": {
              maxWidth: "550px",
              paddingLeft: "20px",
              paddingRight: "20px",
            },
            "@media (max-width: 992px)": {
              paddingLeft: "0px !important",
              paddingRight: "0px !important",
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${Dashboard_bg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              height: "100%",

              position: "relative",
            }}
          >
            <LanguageSelector />
            <Outlet />
          </Box>
        </Container>
      </Box>
    ),
    children: [
      {
        path: "/",
        element: <UserLogin />,
      },
      {
        path: "/user/:billboardId",
        element: <SplashHome />,
      },
      {
        path: "/user/code-verification/:phone",
        element: <Codeverification />,
      },
      {
        path: "/user/:Id/ads",
        element: <UserAds />,
      },
      {
        path: "/user/personal-information/:country/:phone",
        element: <Information />,
      },
      {
        path: "/user/:Id/advertising-policy",
        element: <AdvertisingPolicy />,
      },
      {
        path: "/user/:billboardId/upload-ad",
        element: <UploadAds />,
      },
      {
        path: "/user/dashboard",
        element: <UserDashboard />,
      },
      {
        path: "/user/dashboard/scanner",
        element: <QRScanner />,
      },
      {
        path: "/user/profile",
        element: <Profile />,
      },
      {
        path: "/user/manage-profile",
        element: <ManageProfile />,
      },
    ],
  },
];

export default routes;
