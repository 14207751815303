import { useContext } from "react";
import { useParams } from "react-router-dom";
import { PermissionContext } from "./layout/user.provider";

export const usePermission = (permission) => {
  const { userPermission } = useContext(PermissionContext);
  return (
    Array.isArray(userPermission) ? userPermission : [userPermission]
  ).includes(permission);
};

export default function Permission({ children, permission, checkBillboard }) {
  const { billboards } = useContext(PermissionContext);
  const { name } = useParams();

  const havePermission =
    usePermission(permission) &&
    (checkBillboard ? billboards.includes(name) : true);

  if (havePermission) return <>{children}</>;

  return (
    <div style={{ padding: 20 }}>
      <h1>Sorry! You don't have permission to this page.</h1>
    </div>
  );
}
