import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Invoice } from "./invoice-styled";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getInvoice } from "../../settings/invoice/awaiting-approval/invoice.api";
import moment from "moment";
import {
  DISCOUNT_TYPE,
  INVOICE_TYPE,
  PAYMENT_MODE,
} from "../../settings/invoice/awaiting-approval/invoice.data";
import Invoice2 from "./Invoice2";

const InvoiceGenrate = () => {
  const pdfExportComponent = useRef(null);
  const [query] = useSearchParams();
  const [invoiceData, setInvoiceData] = useState("");
  const [isopen, setIsopen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (!!invoiceData) {
      setIsopen(true);
      return;
    }
    toast.error("Data not found for this Invoice!");
  };

  const handleClose = () => {
    setIsopen(false);
  };

  const getInvoiceDetails = async (id) => {
    await getInvoice(id)
      .then(({ data }) => {
        setInvoiceData(data.data);
      })
      .catch(({ res }) => {
        toast.error("Data not found for this Invoice!");
      });
  };

  useEffect(() => {
    if (query.get("invoice")) {
      getInvoiceDetails(query.get("invoice"));
      return;
    }
    toast.error("Something went Wrong!");
  }, [query]);

  return (
    <Invoice sx={{ my: 3 }}>
      <Container maxWidth="xl">
        <PDFExport
          ref={pdfExportComponent}
          scale={0.5}
          paperSize="A4"
          margin="0"
          fileName={`${invoiceData?._id?.substr(-5)} Invoice`}
        >
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack direction={"column"} spacing={1}>
                  <Button
                    style={{
                      width: "fit-content",
                    }}
                    onClick={() => navigate(-1)}
                    variant="text"
                  >
                    Back
                  </Button>
                  <Box>
                    <Button variant="outlined">
                      {invoiceData.status?.split("_")?.join(" ")}
                    </Button>
                  </Box>

                  <Typography variant="h1">
                    {invoiceData?._id?.substr(-5)}
                  </Typography>
                  <Typography variant="h1">Bill To,</Typography>
                  <Typography>
                    {invoiceData.type === INVOICE_TYPE.INCOME
                      ? "Advertiser"
                      : invoiceData.customer
                      ? "Partner"
                      : "Employee"}{" "}
                    Name:{" "}
                    {(invoiceData.customer || invoiceData.employee)?.fullname}
                  </Typography>
                  <Typography>
                    Shoof Name: {invoiceData.billboard?.billboardName}
                  </Typography>
                  <Typography>
                    Address:{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.street
                    }
                    ,{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.city
                    }
                    ,
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.country
                    }
                    ,{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.pincode
                    }
                  </Typography>
                  <Typography>
                    Phone No. :{" "}
                    {(invoiceData.customer || invoiceData.employee)?.phone}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction={"column"} spacing={1} alignItems={"flex-end"}>
                  <Box>
                    <Button
                      disableRipple={true}
                      variant="text"
                      onClick={handleClick}
                    >
                      Download
                    </Button>
                  </Box>
                  <Typography variant="h1">
                    Invoice Date:{" "}
                    {moment(invoiceData?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography variant="h1">
                    Due Date:{" "}
                    {moment(invoiceData?.invoice_due_date).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={12} item>
                <TableContainer sx={{ mb: 3 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#F1F5F9",
                          "& .MuiTableCell-root": {
                            border: "0px",
                            borderRight: "1px solid #9B9B9B",
                            "&:last-child": {
                              borderRight: "0px",
                            },
                          },
                        }}
                      >
                        <TableCell>item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Unit Rate</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.item_tax?.map((item) => (
                        <TableRow
                          sx={{
                            "&:nth-child(even)": { background: "#F8F8F8" },
                            "& .MuiTableCell-root": {
                              border: "0px",
                              borderRight: "1px solid #9B9B9B",
                              "&:last-child": {
                                borderRight: "0px",
                              },
                            },
                          }}
                        >
                          <TableCell>{item.item_name}</TableCell>
                          <TableCell>{item.item_description}</TableCell>
                          <TableCell>{item?.qty}</TableCell>
                          <TableCell>{item?.item_rate}</TableCell>
                          <TableCell>
                            {item.tax_name} : {item.tax_rate}%{" "}
                          </TableCell>
                          <TableCell>
                            {(item?.qty * item?.item_rate).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={4} />
                        <TableCell className="discount">Subtotal</TableCell>
                        <TableCell>
                          <b>{invoiceData?.sub_total?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>

                      {invoiceData.item_tax?.map((item) =>
                        item.tax_rate !== 0 ? (
                          <TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                border: "0px",
                              },
                            }}
                          >
                            <TableCell colSpan={4} />
                            <TableCell className="discount">
                              {item.tax_name} : {item.tax_rate}%{" "}
                            </TableCell>
                            <TableCell>
                              {(item?.tax_value).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )
                      )}

                      {invoiceData?.discount_type === "NO_DISCOUNT" ? (
                        ""
                      ) : (
                        <TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              border: "0px",
                            },
                          }}
                        >
                          <TableCell colSpan={4} />
                          <TableCell className="discount">
                            ({DISCOUNT_TYPE[invoiceData?.discount_type]})
                            Discount :{" "}
                            {invoiceData?.discount_percentage
                              ? `${invoiceData?.discount}%`
                              : `${invoiceData?.discount}.00`}
                          </TableCell>
                          <TableCell>
                            <b>{invoiceData?.discount_amount?.toFixed(2)}</b>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={4} />
                        <TableCell className="discount">Adjustment</TableCell>
                        <TableCell>
                          <b>{invoiceData?.adjustment?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          background: "#F1F5F9",
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={2}>
                          Mode of Payment :{" "}
                          {PAYMENT_MODE[invoiceData?.payment_mode]}
                        </TableCell>
                        <TableCell colSpan={2} />
                        <TableCell className="discount">Total</TableCell>
                        <TableCell>
                          <b>{invoiceData.total?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {invoiceData.admin_note !== "" &&
                invoiceData.admin_note !== "<p><br></p>" ? (
                  <div style={{ marginLeft: "1%" }}>
                    <Typography variant="h5">Admin Notes</Typography>
                    <Box
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5A6474",
                        marginBottom: "25px",
                      }}
                    >
                      {invoiceData?.admin_note
                        ?.replace(/<p>/g, "")
                        .replace(/<\/p>/g, "")}
                    </Box>
                  </div>
                ) : (
                  ""
                )}
                {invoiceData.terms_and_conditions !== "<p><br></p>" &&
                invoiceData.terms_and_conditions !== "" ? (
                  <div style={{ marginLeft: "1%" }}>
                    <Typography variant="h5">Terms & Conditions</Typography>
                    <Box
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5A6474",
                      }}
                    >
                      {invoiceData?.terms_and_conditions
                        ?.replace(/<p>/g, "")
                        .replace(/<\/p>/g, "")}
                    </Box>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Paper>
        </PDFExport>
      </Container>
      <Invoice2
        invoiceData={invoiceData}
        isopen={isopen}
        handleClose={handleClose}
      />
    </Invoice>
  );
};
export default InvoiceGenrate;
