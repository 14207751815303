import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";

const QRScanner = () => {
  const { t } = useTranslation();
  const [result, setResult] = useState("");
  const navigate = useNavigate();


  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      setTimeout(() => {
        setResult(""); // Clear result to trigger transition
        setTimeout(() => {
          window.location.replace(data.text);
        }, 500); // Adjust the delay time as needed
      }, 500); // Adjust the delay time as needed
    }
  };

  return (
    <div className={`qr-scanner ${result ? "fade-out" : ""}`}>
      <p>{t("scanner")}</p>
      <QrReader
        scanDelay={300}
        onResult={handleScan}
        constraints={{ facingMode: "environment" }}
      />
      {/* <p>{result}</p> */}
    </div>
  );
};

export default QRScanner;
