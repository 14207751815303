import React from "react";
import ReactApexChart from "react-apexcharts";

const DetailsChart = ({ data, width, height }) => {
  const yAxis = Object.keys(data).map((item) => {
    return item;
  });

  const xAxis = Object.values(data).map((itm) => {
    return itm;
  });

  const [state] = React.useState({
    series: [
      {
        name: "Inflation",
        data: xAxis,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",

        toolbar: {
          show: false,
        },
      },
      colors: ["#3AC4F2"],
      plotOptions: {
        bar: {
          borderRadius: 5,
          horizontal: false,
          columnWidth: "40%",
          dataLabels: {
            position: "top",
          },
        },
      },
      responsive: [
        {
          breakpoint: 992,
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,

              offsetY: 0,
              offsetX: -10,
              style: {
                fontSize: "8px",
              },
            },

            xaxis: {
              labels: {
                show: true,
                style: {
                  fontSize: "7px",
                },
              },
            },
            yaxis: {
              labels: {
                show: true,
                offsetX: -10,
                formatter: function (val) {
                  return val;
                },
                style: {
                  fontSize: "7px",
                },
              },
            },
          },
        },
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,

        style: {
          colors: ["#22294399"],
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },

      xaxis: {
        categories: yAxis,
        labels: {
          show: true,
          style: {
            colors: ["#22294399"],
            fontSize: "12px",
            fontFamily: "Poppins",
            fontWeight: 400,
          },
        },
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },

        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          offsetX: -20,
          formatter: function (val) {
            return val;
          },
          style: {
            colors: ["#22294399"],
            fontSize: "12px",
            fontFamily: "Poppins",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
    },
  });
  return (
    <>
      <div className="overflow-m">
        <div className="">
          <ReactApexChart
            options={state.options}
            series={state.series}
            type="bar"
            height={height || 350}
            width={width || "100%"}
          />
        </div>
      </div>
    </>
  );
};
export default DetailsChart;
