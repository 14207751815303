import { useCallback } from "react";
import ProtectedRoute from "../../auth/ProtectedRoute";
import React, { useState } from "react";
import { GetSelf } from "../../api/auth";
import { createContext } from "react";
import Header from "../header/Header";
import { SocketProvider } from "../../utils/socket/socket.provider";
import { sortBillboards } from "../../utils/helper";

export const PermissionContext = createContext({
  userPermission: null,
  billboards: [],
  userData: null,
});

const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const loadData = useCallback(() => {
    GetSelf()
      .then(({ data }) => {
        if (data && data.status === "success") {
          setUserData(data.data.doc);
          return;
        }

        window.location.replace("/auth");
      })
      .catch(() => window.location.replace("/auth"));
  }, []);

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <div className="App">
        <ProtectedRoute
          Component={() =>
            userData ? (
              <PermissionContext.Provider
                value={{
                  userPermission: userData.permission,
                  billboards: (userData.billboards || []).sort(sortBillboards),
                  userData,
                  loadUser: loadData,
                }}
              >
                <SocketProvider>
                  <>
                    <Header />
                    {children}
                  </>
                </SocketProvider>
              </PermissionContext.Provider>
            ) : (
              <div> Data is Loading...</div>
            )
          }
        />
      </div>
    </div>
  );
};

export default UserProvider;
