import moment from "moment";
import api from "./api";
import { FCM } from "@capacitor-community/fcm";
import { requestForToken } from "../utils/firebase";

export const signIn = async (data) => {
  const response = await api.request({
    url: "/v1/login",
    method: "POST",
    data,
  });

  if (response.remote === "success") {
    localStorage.setItem("token", response.data.token);
    window.location.replace("/");
  }
  return response;
};

export const forget = async (data) => {
  const response = await api.request({
    url: "/v1/forgotPassword",
    method: "POST",
    data,
  });

  return response;
};

export const logOut = async () => {
  let deviceId = null;
  try {
    deviceId = await FCM.getToken();
  } catch {
    try {
      deviceId = await requestForToken();
    } catch {}
  }

  const response = await api.request({
    url: "/v1/ledpro/logout",
    method: "POST",
    data: {
      deviceId,
    },
  });
  localStorage.removeItem("token");
  localStorage.removeItem("isOnboardingDone");
  localStorage.removeItem("address");
  localStorage.removeItem("phone");
  localStorage.removeItem("policy");

  return response;
};

export const CreateUser = async (data) => {
  const response = await api.request({
    url: "/v1/create-user",
    method: "POST",
    data,
  });
  return response;
};

export const Alluser = async (limit, page, sortBy, search) => {
  const response = await api.request({
    url: `/v1/admin/alluser?limit=${limit}&page=${page}&sort=${sortBy}&${
      search ? "search=" + search : ""
    }`,
    method: "GET",
  });
  if (response.remote === "success") response.data.data.totalResults = 100;
  //
  return response;
};

export const GetUser = async (id) => {
  const response = await api.request({
    url: `/v1/admin/getuser/${id}`,
    method: "GET",
  });

  return response;
};

export const GetSelf = async () => {
  const response = await api.request({
    url: `/v1/ledpro/getself/`,
    method: "GET",
  });

  return response;
};

export const GetSelfUpdate = async (data) => {
  const response = await api.request({
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    url: `/v1/ledpro/updateMe`,
    method: "PATCH",
    data,
  });
  if (response.remote === "success") {
    localStorage.setItem("isOnboardingDone", "true");
  }
  return response;
};

export const PostAd = async (data) => {
  const response = await api.request({
    config: {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    url: `/v2/ad`,
    method: "POST",
    data,
  });
  return response;
};

export const GetSelfUpdatePassword = async (data) => {
  const response = await api.request({
    url: `/v1/update-password/`,
    method: "PATCH",
    data,
  });
  return response;
};

export const PasswordReset = async (data, id) => {
  const response = await api.request({
    url: `/v1/resetPassword/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetallBillboard = async (sort = "") => {
  const response = await api.request({
    url: `v1/billboard/all-billboard?sort=${sort}`,
    method: "GET",
  });
  return response;
};

export const GetAllCameraBillboard = async () => {
  const response = await api.request({
    url: `v1/billboard/all-camera-billboard`,
    method: "GET",
  });
  return response;
};

export const GetBillboardApi = async (id) => {
  const response = await api.request({
    url: `v1/billboard/${id}`,
    method: "GET",
  });
  return response;
};

export const GetBillboardByName = async (name) => {
  const response = await api.request({
    url: `v1/billboard/name?billboard=${name}`,
    method: "GET",
  });
  return response;
};

export const DeleteRemark = async (id) => {
  const response = await api.request({
    url: `v1/billboard/delete-remark/${id}`,
    method: "DELETE",
  });
  return response;
};

export const GetReportBillsOffline = async (names) => {
  const response = await api.request({
    url: `v1/billboard/multiple-bill-offline`,
    method: "POST",
    data: {
      billboards: names,
    },
  });
  return response;
};

export const RegisterGuestUser = async (
  number,
  country,
  countryCode,
  token
) => {
  const response = await api.request({
    url: `v1/create-guest-user`,
    method: "POST",
    data: {
      phone: number,
      role: "GuestUser",
      country,
      countryCode,
      lng: localStorage.getItem("i18nextLng"),
      token,
    },
  });
  return response;
};

export const ResendOTP = async (data) => {
  const response = await api.request({
    url: `v1/resend-otp`,
    method: "PATCH",
    data,
  });
  return response;
};

export const VerifyOtp = async (data) => {
  const response = await api.request({
    url: `v1/verify-otp`,
    method: "PATCH",
    data,
  });
  if (response.remote === "success") {
    localStorage.setItem("token", response.data.token);
    localStorage.setItem(
      "isOnboardingDone",
      response?.data?.data?.user?.isOnboardingDone
    );
    localStorage.setItem("phone", response?.data?.data?.user?.phone);
    localStorage.setItem(
      "address",
      JSON.stringify(response?.data?.data?.user?.address)
    );
  }
  return response;
  return response;
};

export const UpdateBillboardByName = async (data, name) => {
  const response = await api.request({
    url: `v1/billboard/update-billboard?billboard=${name}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const Updatebillboard = async (data, id) => {
  const response = await api.request({
    url: `/v1/billboard/update-billboard/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetUpdateUser = async (data, id) => {
  const response = await api.request({
    url: `/v1/updateuser/${id}`,
    method: "PATCH",
    data,
  });
  return response;
};

export const GetAllSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/summary`,
    method: "GET",
  });
  return response;
};

export const GetSummaryByDate = async (date, skip, name) => {
  const response = await api.request({
    url: `/v1/c1/summary?summary_created=${date}&&skip=${skip}&&name=${name}`,
    method: "GET",
  });
  return response;
};

export const GetBillboardAds = async (name) => {
  const response = await api.request({
    url: `/v2/ad-guestUser?billboard=${name}`,
    method: "GET",
  });
  return response;
};

export const GetUserAds = async () => {
  const response = await api.request({
    url: `/v2/ledpro/ad`,
    method: "GET",
  });
  return response;
};

export const LastScan = async () => {
  const response = await api.request({
    url: `/v1/logs/last-scan`,
    method: "GET",
  });
  return response;
};

export const GetLastScan = async () => {
  const response = await api.request({
    url: `/v1/c1/summary`,
    method: "GET",
  });
  return moment(response.data.documents.data.summary_created).format(
    "DD MMM YY, HH:mm:ss"
  );
};

export const GetReportBillOffline = async (billboard) => {
  const response = await api.request({
    url: `/v1/billboard/report-offline/${billboard}`,
    method: "GET",
  });
  return response;
};

export const CameraStatus = async () => {
  const response = await api.request({
    url: `/v1/c1/camerastatus`,
    method: "GET",
  });
  return response;
};

export const RealSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/realtime-summary`,
    method: "GET",
  });
  return response;
};

// Master Summary
export const MasterSummary = async () => {
  const response = await api.request({
    url: `/v1/c1/master-summary`,
    method: "GET",
  });
  return response;
};

export const SummaryDev = async () => {
  const response = await api.request({
    url: `/v1/c1/summary-dev`,
    method: "GET",
  });
  return response;
};

export const OfflineBillboards = async (live = false) => {
  const response = await api.request({
    url: `/v1/billboard/offline?live=${live}`,
    method: "GET",
  });
  return response;
};

export const StatisticReport = async (type = "today") => {
  const response = await api.request({
    url: `/v1/c1/stat-report?type=${type}`,
    method: "GET",
  });
  return response;
};

export const GetRushHour = async () => {
  const response = await api.request({
    url: `/v1/c1/rush-hour`,
    method: "GET",
  });
  return response;
};

export const DeleteUser = async (id) => {
  const response = await api.request({
    url: `/v1/delete-user/${id}`,
    method: "DELETE",
  });
  return response;
};

export const CreateSmtp = async (data) => {
  const response = await api.request({
    url: "/v1/smptp/create-smptp",
    method: "POST",
    data,
  });

  return response;
};

export const getSMTP = () => {
  return api.request({
    url: "/v1/smptp/smptp",
    method: "GET",
  });
};

//
