export const ADMIN_MENU = [
  {
    page: "Dashboard",
    url: "/ads/dashboard",
    permission: "ads-dashboard",
  },
  {
    page: "Library",
    url: "/ads/library",
    permission: "ads-library",
  },
  {
    page: "History",
    url: "/ads/history",
    permission: "ads-history",
  },
  {
    page: "Pending",
    url: "/ads/pending",
    permission: "ads-pending",
  },
  {
    page: "Uploader",
    url: "/ads/uploader",
    permission: "ads-uploader",
  },
  {
    page: "Advertisers",
    url: "/ads/advertisers",
    permission: "ads-advertiser",
  },
];
