import { isPlatform } from '@ionic/react'
import { useEffect } from 'react'
import { AppUpdate } from '@capawesome/capacitor-app-update';
import Swal from 'sweetalert2';

export default function CheckUpdate() {

    useEffect(() => {
        if (!isPlatform("capacitor")) {
            return;
        }
        AppUpdate.getAppUpdateInfo().then(appUpdateInfo => {
            if (appUpdateInfo.currentVersion === appUpdateInfo.availableVersion) {
                return
            }
            // Alert here
            Swal.fire({
                text: 'New Update for App is Available.',
                showCancelButton: true,
                confirmButtonText: 'Update',
                cancelButtonText: 'Cancel'
            }).then((result) => {
                if (result.isConfirmed)
                    AppUpdate.openAppStore()
            })
        })
    }, [])

    return null
}
