import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ContactDetails from "../../billboard/edit/contact-details";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function EditModal(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth="sm"
      >
        <DialogContent style={{ overflow: "hidden" }}>
          <DialogContentText id="alert-dialog-description">
            <div className="d-flex justify-content-end">
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <ContactDetails />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
