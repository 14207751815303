import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                fontFamily: 'Arial, sans-serif'
            }}
        >
            <h1
                style={{
                    fontSize: '3rem',
                    marginBottom: '1rem'
                }}
            >404 - Not Found</h1>
            <p
                style={{
                    fontSize: '1.5rem'
                }}
            >The page you are looking for does not exist.<Link to={-1}>Back</Link></p>
        </div>
    );
};

export default NotFound;
