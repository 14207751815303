import React from "react";
import { Button } from "react-bootstrap";
import "./commonbutton.css";

const CommonButton = (props) => {
  return (
    <Button
      variant="false"
      className={`${props.className}`}
      disabled={props.disabled}
      type={props.submit}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
export default CommonButton;
