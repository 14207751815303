import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const requestForToken = async () => {
    try {
        // Replace this firebaseConfig object with the configurations for the project you created on your firebase console. 
        var firebaseConfig = {
            apiKey: "AIzaSyDKWkUCH69_drSmnzdyhqaUursVUZshNeo",
            authDomain: "app-mas-84fe7.firebaseapp.com",
            databaseURL: "https://app-mas-84fe7-default-rtdb.firebaseio.com",
            projectId: "app-mas-84fe7",
            storageBucket: "app-mas-84fe7.appspot.com",
            messagingSenderId: "975889103913",
            appId: "1:975889103913:web:510bd1edf335f2c67fb038",
            measurementId: "G-DXQ3EJ9KS7"
        };

        initializeApp(firebaseConfig);
        const messaging = getMessaging();
        return getToken(messaging, { vapidKey: "BFyhBfqyUsmrASm7en1UTsn2KGqoj_SLCrUq6SzR6o-4qU7fiOs_xnpkbfapxRlKz5PEl0hxf34LG6TSWMWoBVY" })
    } catch { }
};
