import React from "react";

import { Box } from "@mui/material";

import { RotatingLines } from "react-loader-spinner";
import Logo from "./icon/logo.svg";
import spalsh_bg from "../../images/spalsh-screen.png";
import spalshVideo from "../../images/Main.mp4";

const SplashScreen = () => {
  return (
    <>
      {/* <Box
        component={"main"}
        sx={{
          backgroundImage: `url(${spalsh_bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      > */}
        <Box>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <source src={spalshVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <img alt="" src={Logo} /> */}
        </Box>
        {/* <Box
          sx={{
            position: "absolute",
            bottom: "50px",
            left: "0px",
            right: "0px",
            textAlign: "center",
          }}
        >
          <RotatingLines
            visible={true}
            width="50"
            strokeWidth="2"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            strokeColor="#fff"
          />
        </Box> */}
      {/* </Box> */}
    </>
  );
};

export default SplashScreen;
