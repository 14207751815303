import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useSearchParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
  },
}));
const TableList = ({ rows, columns ,sort }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(1);
  const [query, setQuery] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(true);

  const createSortHandler = (property) => (event) => {
    if(sort){
      return;
    }
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    query.set("sort", `${isAsc ? "" : "-"}${property}`);
    setQuery(query);
    setOrderBy(property);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [rows]);

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 500 }} aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow className="table-head-bg">
              {(columns || []).map((data) => {
                return (
                  <TableCell
                    sx={{
                      width: data.width,
                      padding: "16px 5px",
                      whiteSpace: "nowrap",
                    }}
                    key={data.id}
                    sortDirection={orderBy === data.key ? order : false}
                  >
                    {data.key === "_id" || data.key === "action" ? (
                      data.name
                    ) : (
                      <TableSortLabel
                        active={orderBy === data.key}
                        direction={orderBy === data.key ? order : "asc"}
                        onClick={createSortHandler(data.key)}
                      >
                        {data.name}
                      </TableSortLabel>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow sx={{ fontSize: "1.5em", textAlign: "center" }}>
              <TableCell
                colSpan={(columns || []).length}
                sx={{ textAlign: "center", fontSize: "1.2em" }}
              >
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <TableBody className="tablebody-first">
              {(rows || []).map((row) => (
                <TableRow
                  hover={true}
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& > td": {
                      width: 100,
                    },
                  }}
                >
                  {(columns || []).map((data, idx) => {
                    return (
                      <TableCell
                        label={row[data.key]}
                        key={idx}
                        className={data.class}
                        sx={{
                          padding: "16px 5px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        <BootstrapTooltip
                          title={
                            row[data.key] === "Client"
                              ? "Partner"
                              : row[data.key]
                          }
                        >
                          <>
                            {row[data.key] === "Client"
                              ? "Partner"
                              : row[data.key]}
                          </>
                        </BootstrapTooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}

              {(rows || []).length <= 0 && (
                <TableRow sx={{ fontSize: "1.5em", textAlign: "center" }}>
                  <TableCell
                    colSpan={(columns || []).length}
                    sx={{ textAlign: "center", fontSize: "1.2em" }}
                  >
                    Data is not available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};
export default TableList;
