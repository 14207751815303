import { Card, CardContent, Grid } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import TableList from "../../table";
import { Link, useSearchParams } from "react-router-dom";
import {
  ActionIcon,
  formateUserData,
  USER_COLUMNS_DATA,
  ActionIconDelete,
  UserIcon,
  UserTest,
} from "../../table/Data";
import { Stack } from "@mui/material";
import EditModal from "../../modal/EditModal";
import plushicon from "../../../../images/plushicon.png";
import { Alluser, DeleteUser } from "../../../../api/auth";
import ReactPaginate from "react-paginate";
import { usePermission } from "../../../Permission";

const GuestUserManagement = () => {
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState([]);
  const [dataId, setDataId] = React.useState(null);
  const [sortings, setSortings] = React.useState([]);
  const [query] = useSearchParams();

  const hasCreatePermission = usePermission("user-create");
  const hasEditPermission = usePermission("user-edit");

  const handleClickOpen = useCallback(
    (id) => {
      setOpen(!open);
      if (dataId) {
        setDataId(null);
      } else {
        setDataId(id);
      }
    },
    [dataId, open]
  );

  // Pagination Start
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);

  const DeleteData = useCallback(async (id) => {
    if (window.confirm("Do You Want To delete User ?")) {
      let deleteusers = await DeleteUser(id);

      if (deleteusers.remote === "success") {
        setUserData((prevState) => prevState.filter((user) => user.id !== id));
      }
    }
  }, []);

  const getAlluser = useCallback(
    async (page) => {
      const limit = 10;
      const promise = Alluser(limit, page, sortings || "");

      const response = await promise;

      if (response.remote === "success") {
        setTotalPages(Math.ceil(response.data.data.total / limit));
        response.data.data.docs = response.data.data.docs.filter(
          (mydata) => mydata.role === "GuestUser"
        );

        const myalldata = response.data.data.docs.map((mydata) => {
          if (mydata?.role === "GuestUser") {
            return {
              id: mydata?._id,
              name: mydata.firstname + " " + mydata.lastname,
              email: mydata.email,
              role: mydata.role,
              permissions: mydata.permission,
              lastlogin: mydata.lastLogin,
              online: mydata.online,
              photo: mydata.photo,
            };
          }
        });

        const newUserData = myalldata.map((item, index) => ({
          id: item.id.toString(),
          firstname: (
            <UserIcon
              editPermission={hasEditPermission}
              name={item.name}
              id={item.id}
              photo={item.photo}
            />
          ),
          email: item.email,
          online: (
            <UserTest
              className={item.online ? "text-green" : "text-red"}
              text={item.online ? "Online" : "Offline"}
            />
          ),
          role: item.role,
          permissions: item.permissions,
          lastLogin: item.lastlogin
            ? item.lastlogin.slice(0, 10)
            : "Not Logged In",
          action: (
            <Stack
              style={{ position: "sticky", right: 0 }}
              direction="row"
              spacing={1}
              alignItems="center"
            >
              {hasEditPermission && (
                <>
                  <Link
                    to={`/my-profile/user-edit/${item.id}?user=GuestUser`}
                    key={index}
                    state={{ action: "edit" }}
                  >
                    <ActionIcon />
                  </Link>

                  <ActionIconDelete deletepass={() => DeleteData(item.id)} />
                </>
              )}
            </Stack>
          ),
        }));

        setUserData([...newUserData]);
      } else {
      }
    },
    [DeleteData, sortings]
  );

  useEffect(() => {
    if (page) {
      getAlluser(page);
      setSortings(query.get("sort"));
    }
  }, [getAlluser, page, query]);

  // Delete User

  const handleSetPage = (data) => {
    let nextPage = data.selected + 1;
    setPage(nextPage);
  };

  return (
    <>
      <Grid item lg={10} xs={12}>
        <Card
          variant="outlined"
          sx={{ minHeight: "calc((100vw/2 - 307px) * 1.34)" }}
          id="table-body-user-list"
        >
          <CardContent>
            <TableList
              rows={userData}
              columns={USER_COLUMNS_DATA}
              handleClickOpen={handleClickOpen}
              deletepass={DeleteData}
              onSort={setSortings}
            />

            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="mt-3 ms-3"
            >
              {hasCreatePermission && (
                <Link
                  to="/my-profile/user-edit"
                  style={{ textDecoration: "none", color: "#000" }}
                  state={{ action: "new" }}
                >
                  {/* <span className="addiconbox">
                    <img src={plushicon} alt="" />
                  </span>

                  <span className="addtext ms-2">Add a guest user</span> */}
                </Link>
              )}
              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                pageRangeDisplayed={10}
                pageCount={totalPages}
                forcePage={page - 1}
                onPageChange={handleSetPage}
                activeClassName={"active"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <EditModal open={open} handleClose={() => handleClickOpen(null)} />
    </>
  );
};
export default GuestUserManagement;
