import React, { useEffect, useState } from "react";

// import dynamic from "next/dynamic";
import ReactApexChart from "react-apexcharts";

const LineChart = (graph) => {
  // console.log("\n\n\n<LineChart> graph", graph?.graph);
  

  const [chartData, setChartData] = useState({
    series: [
      {
        // name: "Desktops",
        data: graph?.graph?.values
          ? graph?.graph?.values
          : [(0, 500, 1000, 1500, 2000, 2500)],
      },
    ],
    options: {
      chart: {
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,

        width: 2,
        dashArray: 0,
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      markers: {
        size: [4, 4],
      },
      xaxis: {
        categories: graph?.graph?.months
          ? graph?.graph?.months
          : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        labels: {
          style: {
            colors: [
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
            ], // Change label color here
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
              "#F9C809",
            ], // Change label color here
          },
        },
      },
      colors: ["#FF1E1E"],
    },
  });

  useEffect(() => {
    if (graph?.graph) {
      setChartData((prev) => ({
        ...prev,
        series: [{ data: graph.graph.values }],
        options: {
          ...prev.options,
          xaxis: { categories: graph.graph.months },
        },
      }));
    }
  }, [graph]);

  return (
    <>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={300}
      />
    </>
  );
};
export default LineChart;
