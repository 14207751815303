"use client";

import {
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  // Select,
  Stack,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "../components/commanButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowLeft from "../components/icon/arrow-left.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RegisterGuestUser } from "../../api/auth";
import { Loader } from "../components/Loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/languageSelector";
import ReCAPTCHA from "react-google-recaptcha";
const UserLogin = () => {
  // Translation
  const { t, i18n } = useTranslation();
  // const { name } = useParams();
  const [isCountry, setIsCountry] = useState({});
  const [loading, setLoading] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const name = localStorage.getItem("billboardName");
  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA state
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const validationSchema = Yup.object({
    phone: Yup.string().required(t("validation.phone")),
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isOnboardingDone = localStorage.getItem("isOnboardingDone");
    const phone = localStorage.getItem("phone");
    const address = JSON.parse(localStorage.getItem("address"));
    const policy = localStorage.getItem("policy");
    const billboardId = localStorage.getItem("billboardId");

    if (token) {
      if (isOnboardingDone === "true") {
        if (policy) {
          navigate(`/user/${billboardId}/upload-ad`);
        } else {
          navigate(`/user/dashboard`);
        }
        // console.log("\n<Login> isOnboardingDone", isOnboardingDone);
      } else {
        navigate(`/user/personal-information/${address?.country}/${phone}`);
      }
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      // Remove leading zero after dial code
      const spilttedValues = values.phone.substring(
        values.country.dialCode.length
      );
      const newValue = spilttedValues.startsWith("0")
        ? spilttedValues.substring(1)
        : spilttedValues;
      values.phone = values.country.dialCode + newValue;
      // console.log("\n\n\n<Userlogin> values", values);
      // return;

      // Call the API to register the guest user
      setLoading(true);
      const response = await RegisterGuestUser(
        values?.phone,
        values?.country?.label,
        values?.country?.value,
        captchaValue
      );
      console.log(response); // Handle the API response as needed
      if (response.remote === "success") {
        navigate(`/user/code-verification/${formik?.values?.phone}`);
      } else if (response?.response?.status === 422) {
        toast.warn(response?.response?.data?.message);
      }

      // Navigate to the next step or perform other actions
    } catch (error) {
      console.error("Error registering guest user:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      country: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleChange = (event) => {
    setIsCountry(event);
  };
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("<Login>  formik", formik?.values?.country?.value);
    setIsCountry(formik?.values?.country?.value);
    window.scrollTo(0, 0);
  }, [setIsCountry]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "linear-gradient(110deg, #151313 0%, #323131 100%)",
      color: "#d5d5d5",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        background: isDisabled
          ? "red"
          : "linear-gradient(110deg, #151313 0%, #323131 100%)",
        color: isSelected ? "#FBC515" : "#d5d5d5",

        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    placeholder: (styles) => ({
      ...styles,
      color: "white",
    }),
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the captcha token
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            p: 3,
            height: "95%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            direction={"row"}
            // alignItems={"center"}
            spacing={2}
            sx={{ mb: 2.5 }}
          >
            {/* <IconButton
              sx={{
                background: "#fff",
                color: "#000",
                opacity: 1,
                "&:hover": { color: "#000", background: "#fff" },
              }}
              onClick={() => navigate(`/user/ads`)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton> */}
            <Box
              sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
              component={"span"}
            >
              {t("enter_phone")}
            </Box>
          </Stack>

          <Stack
            direction={"column"}
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
            spacing={2}
            sx={{ flex: "1 1 auto", height: "100%" }}
          >
            <FormGroup>
              <FormLabel sx={{ color: "#F9C809", fontSize: "16px", mb: 1 }}>
                {t("country_select")}
              </FormLabel>
              <Select
                styles={colourStyles}
                // styles={{
                //   background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
                //   borderRadius: "8px",
                //   border: "1px solid #4F4F4F",
                //   color: "#fff",
                // }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                options={options}
                value={formik.values.country}
                onChange={(value) => {
                  // console.log("\n\n<Login> value", value);
                  setIsCountry(value);
                  formik.setFieldValue("country", value);
                }}
                onBlur={formik.handleBlur("country")}
                IconComponent={KeyboardArrowDownIcon}
              />
              {formik.touched.country && formik.errors.country && (
                <div style={{ color: "red" }}>{formik.errors.country}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#F9C809", fontSize: "16px", mb: 1 }}>
                {t("mob_num")}
              </FormLabel>
              <PhoneInput
                country={
                  isCountry?.value ? isCountry?.value?.toLowerCase() : ""
                }
                disableDropdown={true}
                value={formik.values.phone}
                onChange={(value, country) => {
                  const spilttedValues = value.substring(
                    country.dialCode.length
                  );
                  const newValue = spilttedValues.startsWith("0")
                    ? spilttedValues.substring(1)
                    : spilttedValues;
                  const phoneNum = country.dialCode + newValue;
                  formik.setFieldValue("phone", phoneNum);
                  formik.setFieldValue("country.dialCode", country.dialCode);
                  // console.log("<UserLogin> newValue", phoneNum);
                }}
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    // console.log(
                    //   "<UserLogin> isValid Invalid value",
                    //   +value + ", " + country.name
                    // );
                    return "Invalid value: " + value + ", " + country.name;
                  } else if (value.match(/1234/)) {
                    // console.log("<UserLogin> isValid else if", false);

                    return false;
                  } else {
                    // console.log("<UserLogin> isValid else ", true);
                    return true;
                  }
                }}
                onBlur={formik.handleBlur("phone")}
                placeholder=""
              />
              {formik.touched.phone && formik.errors.phone && (
                <div style={{ color: "red" }}>{formik.errors.phone}</div>
              )}
            </FormGroup>
            {/* reCAPTCHA component */}
            <FormGroup>
              <ReCAPTCHA
                sitekey={recaptchaSiteKey} // Replace with your actual site key
                onChange={handleCaptchaChange}
              />
            </FormGroup>
          </Stack>
          <Box
          // sx={{
          //   position: "absolute",
          //   bottom: "24px",
          //   left: "16px",
          //   right: "16px",
          // }}
          >
            {/* <Link to={`/user/code-verification/${formik?.values?.phone}`}> */}
            <CustomButton
              btnText={t("next")}
              onClick={() => {
                formik.handleSubmit();
              }}
              // icon={<img src={ArrowLeft} alt="" />}
            />
            {/* </Link> */}
          </Box>
        </Box>
      )}
    </>
  );
};
export default UserLogin;
