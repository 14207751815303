import { Box } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

export const Loader = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "0px",
        right: "0px",
        textAlign: "center",
      }}
    >
      <RotatingLines
        visible={true}
        width="100"
        strokeWidth="2"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        strokeColor="#fff"
      />
    </Box>
  );
};
