import { createContext, useContext, useEffect } from "react";
import io from "socket.io-client";
import { SocketBaseUrl } from "../../api/api";
import { useState } from "react";
import { PermissionContext } from "../../component/layout/user.provider";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const ENDPOINT = SocketBaseUrl;
  const [socket, setSocket] = useState(null);
  const { userData } = useContext(PermissionContext);

  useEffect(() => {
    const new_socket = io(ENDPOINT, {
      extraHeaders: {
        token: token,
      },
    });
    setSocket(new_socket);

    return () => {
      console.log("Socket dissconnect...");
      socket?.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {!!socket && children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
