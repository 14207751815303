import { Box, Stack } from "@mui/material";

import React from "react";
import treearrow from "./icon/arrows.svg";

const CustomButton = (props) => {
  return (
    <>
      <Box
        component={"button"}
        onClick={props.onClick}
        disabled={props?.disabled}
        sx={{
          p: 1,
          width: "100%",
          outline: "none",

          background: props?.disabled
            ? "linear-gradient(90deg, rgba(92,176,66,0.2) 0%, rgba(249,181,8,0.2) 0%, rgba(197,63,4,0.2) 100%)"
            : "linear-gradient(90deg, rgba(92,176,66,1) 0%, rgba(249,181,8,1) 0%, rgba(197,63,4,1) 100%)",
          fontSize: props.size || "20px",
          color: "#fff",
          fontWeight: 400,
          borderRadius: "16px",
          border: "1px solid rgba(255, 255, 255, 0.20)",
          cursor: "pointer",
          "&:hover": {
            background: props?.disabled
              ? "linear-gradient(90deg, rgba(197,63,4,0.2) 0%, rgba(249,181,8,0.2) 100%)"
              : "linear-gradient(90deg, rgba(197,63,4,1) 0%, rgba(249,181,8,1) 100%)",
          },
        }}
      >
        <Stack
          direction={"row"}
          spacing={1.25}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* <Box
            component={"span"}
            sx={{
              background: "#FFF",
              borderRadius: "16px",
              width: "56px",
              height: "56px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            {props.icon}
          </Box> */}
          {props.btnText}
          {/* <img src={treearrow} alt=""/> */}
        </Stack>
      </Box>
    </>
  );
};
export default CustomButton;
