import {
  AppBar,
  Avatar,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation } from "react-router-dom";
import { logOut, GetSelf } from "../../api/auth";
import { baseURLImg } from "../../api/api";
import defaultImg from "../../images/default-user.svg";
import { getTicketCount } from "./Header.api";
import { SocketContext } from "../../utils/socket/socket.provider";
import { PermissionContext } from "../layout/user.provider";
import { getAllTicketCount } from "../ticket/ticket.api";
import { USER_ROLE } from "../settings/invoice/awaiting-approval/invoice.data";
import { SettingViewPermissions } from "../settings/sidebar/helper";
import { ADMIN_MENU } from "../ads/sidebar/helper";

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentSection, setCurrentSection] = React.useState("home");
  const [profileData, setProfileData] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [tickeCount, setTicketCount] = useState(0);
  const [approved, setApproved] = useState(0);
  const socket = useContext(SocketContext);
  const { userData, userPermission } = useContext(PermissionContext);
  const settingFirstPermission = SettingViewPermissions.find((permission) =>
    userData.permission.includes(permission.permission)
  );
  const adsFirstPermission = ADMIN_MENU.find((permission) =>
    userData.permission.includes(permission.permission)
  );

  const viewStatistics = (userPermission || []).some((permission) =>
    [
      "view-daily-statistics",
      "view-weekly-statistics",
      "view-monthly-statistics",
      "view-yearly-statistics",
    ].includes(permission)
  );

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("my-profile")) {
      setCurrentSection("settings");
    }
    if (location.pathname.includes("billboard-details")) {
      setCurrentSection("billboard");
    } else if (location.pathname.includes("statistics")) {
      setCurrentSection("statistics");
    } else if (location.pathname.includes("ticket")) {
      setCurrentSection("ticket");
    }

    //Taking the Ticket count
    getTicketCount().then(({ data }) => {
      setTicketCount(data?.data || 0);
    });
  }, []);

  const getAllTicketsCount = () => {
    getAllTicketCount()
      .then(({ data }) => {
        if (userData.role !== USER_ROLE.Employee) {
          setApproved(data?.approveTicket || 0);
        } else {
          setApproved(data?.assigneeTicket || 0);
        }
      })
      .catch(({ res }) => {});
  };

  //socket count increase of ticket
  React.useEffect(() => {
    socket.on("REFRESH", () => {
      getAllTicketsCount();
      getTicketCount().then(({ data }) => {
        setTicketCount(data?.data || 0);
      });
    });
    if (userData && socket) {
      if (!(userData.role === "Admin" || userData.role === "SuperAdmin")) {
        socket.on("counter", (data) => {
          if (data?.user === userData._id) {
            setTicketCount((prev) => prev + data.count);
          }
        });
      } else {
        socket.on("APPROVED", () => {
          setTicketCount((prev) => prev - 1);
        });
        socket.on("CREATED", () => {
          setTicketCount((prev) => prev + 1);
        });
      }
    }

    return () => {
      socket?.off("counter");
      socket?.off("APPROVE");
      socket?.off("CREATED");
    };
  }, [socket, userData]);

  // logout Start
  const logout = async () => {
    const response = await logOut();
    socket?.disconnect();
    if (response.remote === "success") {
      window.location = "/auth";
    }
  };
  // logout End

  // Get Profile Detalis
  useEffect(() => {
    const getdetalis = async () => {
      const response = await GetSelf();
      if (response.remote === "success") {
        setProfilePic(response.data.data.doc.photo);
        setProfileData(response.data.data.doc);
      } else {
        localStorage.removeItem("token");
        navigate("/auth");
      }
    };

    getdetalis();
    getAllTicketsCount();
  }, []);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          background: "#fff",
          boxShadow: "0px 4px 25px rgba(42, 89, 152, 0.05)",
        }}
      >
        <Container maxWidth="xl">
          <Toolbar sx={{ py: 1 }} className="toolbar">
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link to="/">
                <img src={logo} alt="" className="mobile-size" />
              </Link>
            </Typography>
            <div className="rightbar">
              <Stack
                alignItems="center"
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
              >
                <Link
                  to="/"
                  className={`navLink mobile-none ${
                    currentSection === "home" ? "header-active" : ""
                  }`}
                  onClick={() => setCurrentSection("home")}
                >
                  Home
                </Link>
                {viewStatistics && (
                  <Link
                    to="/statistics"
                    className={`navLink mobile-none ${
                      currentSection === "statistics" ? "header-active" : ""
                    }`}
                    onClick={() => setCurrentSection("statistics")}
                  >
                    Statistics
                  </Link>
                )}

                <Link
                  to="/ticket"
                  className={`navLink mobile-none ${
                    currentSection === "ticket" ? "header-active" : ""
                  }`}
                  onClick={() => setCurrentSection("ticket")}
                >
                  Tickets ({approved})
                  {tickeCount > 0 ? (
                    <span className="count-header">{tickeCount}</span>
                  ) : (
                    ""
                  )}
                </Link>

                {adsFirstPermission && (
                  <Link
                    to={adsFirstPermission.url}
                    className={`navLink mobile-none ${
                      currentSection === "ads" ? "header-active" : ""
                    }`}
                    onClick={() => setCurrentSection("ads")}
                  >
                    Ads
                  </Link>
                )}

                {settingFirstPermission && (
                  <Link
                    to={settingFirstPermission.path}
                    className={`navLink mobile-none ${
                      currentSection === "settings" ? "header-active" : ""
                    }`}
                    onClick={() => setCurrentSection("settings")}
                  >
                    Settings
                  </Link>
                )}
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  variant="contained"
                  className="btn-dropmenu"
                  endIcon={
                    <KeyboardArrowDownIcon
                      className="mobile-none"
                      sx={{ color: "rgba(10, 42, 72, 0.6)" }}
                    />
                  }
                >
                  {!!profilePic ? (
                    <Avatar
                      src={`${baseURLImg}/users/${profilePic}`}
                      alt="Profile Pic"
                      className="me-lg-2"
                    />
                  ) : (
                    <img
                      src={defaultImg}
                      alt=""
                      className="me-lg-2"
                      style={{ width: "45px", borderRadius: "100%" }}
                    />
                  )}
                  <span className="Text-eslips mobile-none">
                    {profileData.fullname}
                  </span>
                </Button>
                {anchorEl && (
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/my-profile");
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                  </Menu>
                )}
              </Stack>
            </div>
          </Toolbar>
          <div className="destop-hide">
            <Stack
              direction="row"
              justifyContent="space-between"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "#3AC4F2", opacity: "1" }}
                />
              }
              className="mobile-menu"
            >
              <Link
                to="/"
                className={`${
                  currentSection === "home" ? "header-active" : ""
                }`}
                onClick={() => setCurrentSection("home")}
              >
                Home
              </Link>
              {viewStatistics && (
                <Link
                  to="/statistics"
                  className={`${
                    currentSection === "statistics" ? "header-active" : ""
                  }`}
                  onClick={() => setCurrentSection("statistics")}
                >
                  Statistics
                </Link>
              )}
              <Link
                to="/ticket"
                className={`${
                  currentSection === "ticket" ? "header-active" : ""
                }`}
                onClick={() => setCurrentSection("ticket")}
              >
                Tickets ({approved})
                {tickeCount > 0 ? (
                  <span className="count-header">{tickeCount}</span>
                ) : (
                  ""
                )}
              </Link>

              <Link
                to="/billboard-details"
                className={`${
                  currentSection === "billboard" ? "header-active" : ""
                }`}
                onClick={() => setCurrentSection("billboard")}
              >
                Billboards
              </Link>
            </Stack>
          </div>
        </Container>
      </AppBar>
    </>
  );
};
export default Header;
