
import { useEffect, useState } from "react";
import Dashboard from "./components/ads";
import SplashScreen from "./components/splash-screen";


export default function SplashHome() {
  const [isSpalsh, setIsSpalsh] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSpalsh(true);
    }, 9000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  return <>{isSpalsh ? <Dashboard /> : <SplashScreen />}</>;
}
