import {
  Box,
  Dialog,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";

import Lock from "../../images/lock.png";
import Calender from "../../images/calendar-2.png";
import UploadIcon from "../../images/upload-newIcon.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomButton from "../components/commanButton";
import ArrowLeft from "../components/icon/arrow-left.svg";
import close from "../components/icon/close-circle.svg";
import Logo from "../components/icon/logo.svg";
import save from "../../images/save-2.png";
import autoicon from "../../images/lifebuoy.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { uploadVideoSchema } from "../../component/ads/uploader/uploader.schema";
import Swal from "sweetalert2";
import { getBillboard } from "../../component/billboard/detail/billboard-detail.api";
import { AuthClient } from "../../api/api";
import { toast } from "react-toastify";
import { getAutomaticCost } from "../../component/ads/uploader/automaticCost.api";
import { GetSelf } from "../../api/auth";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";

export default function UploadAds() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [previewvideo, setPreviewVideo] = useState(null);
  const [disabled, setdisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [gettingCost, setGettingCost] = useState(false);
  const videoRef = useRef(null);
  const { videoWidth, videoHeight, duration } = videoRef?.current || {};
  const [userData, setUserData] = useState([]);
  const [minStartDate, setMinStartDate] = useState(getTodayDate());
  // const [name, setName] = useState("");
  const [billboard, setBillboard] = useState({
    data: null,
    error: false,
  });

  const name = localStorage.getItem("billboardName");
  const billboardId = localStorage.getItem("billboardId");

  // Function to get today's date in the format YYYY-MM-DD
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month starts from 0
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formik = useFormik({
    initialValues: {
      billboard: [{ billboardName: name }],
      type: "local",
      title: "",
      status: "pending",
      video: "",
      closing_at: "",
      start_at: "",
      advertiser: "",
      campaign_cost: 0,
      duration: 0,
    },
    validationSchema: Yup.object({
      billboard: Yup.array().required(t("validation.required")),
      type: Yup.string().required(t("validation.required")),
      title: Yup.string().required(t("validation.required")),
      status: Yup.string().optional(),
      video: Yup.string().required(t("validation.required")),
      start_at: Yup.date()
        .required(t("validation.start_date_required"))
        .typeError(t("validation.invalid_date_format"))
        .test(
          "start_at",
          t("validation.end_date_after_start_date"),
          function (value) {
            const { closing_at } = this.parent;
            if (!value || !closing_at) return true;
            return new Date(value) <= new Date(closing_at);
          }
        ),
      closing_at: Yup.date()
        .required(t("validation.end_date_required"))
        .typeError(t("validation.invalid_date_format"))
        .test(
          "closing_at",
          t("validation.end_date_after_start_date"),
          function (value) {
            const { start_at } = this.parent;
            if (!value || !start_at) return true;
            return new Date(value) >= new Date(start_at);
          }
        ),
      advertiser: Yup.string().required(t("validation.required")),
      campaign_cost: Yup.string().required(t("validation.required")),
      sales_proposal_cost: Yup.string().optional(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const isNotValid = await checkVideo();
      values.duration = Math.round(duration);

      if (isNotValid) {
        setPreviewVideo(null);
        Swal.fire(isNotValid);
        // setLoading(false);
        return;
      }
      setSaving(true);

      values.billboard = values.billboard.map(
        (billboard) => billboard?.billboardName
      );

      const formData = new FormData();
      formData.append("advertiser", values.advertiser);
      // values.billboard.forEach((billboard, index) => {
      //   formData.append(`billboard[${index}]`, billboard);
      // });
      formData.append(`billboard`, values.billboard);
      formData.append("campaign_cost", values.campaign_cost);
      formData.append("closing_at", values.closing_at);
      formData.append("duration", values.duration);
      formData.append("start_at", values.start_at);
      formData.append("status", values.status);
      formData.append("title", values.title);
      formData.append("type", values.type);
      formData.append("video", values.video);
      formData.append("lng", localStorage.getItem("i18nextLng"));
      // console.log("<Upload Ads> Upload Ad Data \t", formData);

      AuthClient()
        .post(`/v2/ledpro/ad`, formData)
        .then(() => {
          toast.success(t("ad_created_success"));
          handleClickOpen();
        })
        .catch((error) => {
          console.log("<Upload Ads> formData", formData);
          toast.error(t("ad_created_failure"));
        })
        .finally(() => setLoading(false));
    },
  });

  // FetchUser
  const fetchUserData = useCallback(async () => {
    try {
      setLoading(true);
      let response = await GetSelf();
      if (response.remote === "success") {
        let data = response?.data?.data?.doc;
        formik.setFieldValue("advertiser", data?._id || "");
        setUserData(data);
        getBillboard(billboardId)
          .then(({ data }) => {
            if (data.status === "success") {
              setBillboard((prev) => ({ ...prev, data: data.data }));
              // console.log("\n\n<Upload Ads> response", data.data);
            } else {
              setBillboard((prev) => ({ ...prev, error: true }));
            }
          })
          .catch(() => {
            setBillboard((prev) => ({ ...prev, error: true }));
          })
          .finally(() => setLoading(false));
      } else {
      }
    } catch (error) {
      toast.error(`Error in getself:${error}`);
    } finally {
      //   setLoading(false);
    }
  }, []);

  useEffect(() => {
    // setName(localStorage.getItem("billboardName"));
    fetchUserData();
  }, []);

  useEffect(() => {
    if (
      saving ||
      !(
        formik.values.billboard &&
        formik.values.start_at &&
        formik.values.closing_at &&
        previewvideo
      )
    ) {
      return;
    }

    setGettingCost(true);

    setTimeout(() => getCost(), 3000);
  }, [
    formik.values.billboard,
    formik.values.start_at,
    formik.values.closing_at,
    previewvideo,
  ]);

  const getCost = async () => {
    const {
      duration: videoDuration,
      videoWidth,
      videoHeight,
    } = videoRef?.current || {};
    // console.log("\n\n<UploadAds> videoDuration", videoWidth, videoHeight);

    try {
      setLoading(true);
      let response = await getAutomaticCost(
        formik.values.billboard,
        formik.values.start_at,
        formik.values.closing_at,
        Math.round(videoDuration)
      );
      if (response.status === 200) {
        formik.setFieldValue(
          "campaign_cost",
          Number(
            response.data.data
              .reduce((s, d) => s + d.campaign_cost, 0)
              .toFixed(2)
          )
        );
      }
      setGettingCost(false);
    } catch {
      formik.setFieldValue("campaign_cost", 0);
      setGettingCost(false);
      setdisabled(false);
    } finally {
      setLoading(false);
      setdisabled(false);
    }
  };

  const checkVideo = async () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    const updateVideo = async () => {
      await formik.setFieldValue("video", "");
    };

    if (videoWidth * 16 !== videoHeight * 9 && videoHeight !== videoWidth * 2) {
      await updateVideo();
      return t("video_resolution_message");
    }

    if (duration > 30) {
      await updateVideo();
      return t("video_length_message");
    }
    formik.setFieldValue("duration", duration);
    return false;
  };

  // useEffect(() => {
  //   console.log("videoRef:", videoRef.current);
  //   console.log("duration:", duration);
  // }, [videoRef.current, duration]);

  const onVideoLoad = async (e) => {
    // Wait for the video to be fully loaded
    await e.target.onloadedmetadata;
    if (e.target.files[0].size < 1000000) {
      Swal.fire(t("video_size_message"));
      return;
    }
    const url = window.URL.createObjectURL(e.target.files[0]);
    // console.log("\n\n<UploadAds> onVideoLoad", url);
    setPreviewVideo(url);
    formik.setFieldValue("video", e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      navigate("/user/dashboard");
    }, 500);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  // useEffect(() => {
  //   console.log("\n\n\n<UploadAds> Formik Values", formik?.values);
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{ p: 3 }}
          dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{ mb: 3 }}
          >
            <IconButton
              sx={{
                background: "#fff",
                color: "#000",
                opacity: 1,
                "&:hover": { color: "#000", background: "#fff" },
              }}
              onClick={() => navigate("/user/dashboard")}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Box
              className="backArrow"
              sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
              component={"span"}
            >
              {t("upload_ad")}
            </Box>
          </Stack>
          <form onSubmit={formik.handleSubmit}>
            <Stack direction={"column"} spacing={2}>
              <FormGroup>
                <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                  {t("ad_name")}
                </FormLabel>
                <TextField
                  placeholder={t("ad_name_title")}
                  fullWidth
                  {...formik?.getFieldProps("title")}
                  // onChange={(e) => {
                  //   console.log("\n\n<UploadAds> Ad Title ", e?.target?.value);
                  // }}
                  // value={formik.values.title}
                  sx={{
                    "& .MuiInputBase-root": {
                      background:
                        "linear-gradient(110deg, #151313 0%, #323131 100%)",
                      border: "1px solid #4F4F4F",
                      borderRadius: "8px",
                      color: "#FBC515",
                      fontSize: "16px",
                      paddingTop: "1px",
                      paddingBottom: "1.5px",
                      "& .MuiOutlinedInput-input": {
                        paddingTop: "12px",
                        paddingBottom: "14.5px",
                      },
                    },

                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={save} />
                      </InputAdornment>
                    ),
                  }}
                />
                {formik.touched.title && formik.errors.title && (
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "x-small",
                      marginLeft: "5px",
                    }}
                  >
                    {formik.errors.title}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel
                  sx={{
                    color: "#FBC515",
                    fontSize: "16px",
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {t("billboard")} <img src={Lock} alt="" />
                </FormLabel>
                <TextField
                  // select
                  fullWidth
                  value={
                    billboard?.data?.address ? billboard?.data?.address : ""
                  }
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-root": {
                      background:
                        "linear-gradient(110deg, #151313 0%, #323131 100%)",
                      border: "1px solid #4F4F4F",
                      borderRadius: "8px",
                      color: "#FBC515",
                      fontSize: "16px",

                      "& .MuiOutlinedInput-input": {
                        paddingTop: "14.5px",
                        paddingBottom: "14.5px",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#FBC515",
                      },
                      "& .MuiSelect-iconOutlined": { display: "none" },
                    },

                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                        <KeyboardArrowDownIcon sx={{ color: "#D5D5D5" }} />
                      </InputAdornment>
                    ),
                  }}
                  defaultValue="1"
                >
                  <MenuItem value={"1"}>5 Hatzoref, Holon, Israel</MenuItem>
                  <MenuItem value={"2"}>5 Hatzoref, Holon, Israel</MenuItem>
                  <MenuItem value={"3"}>5 Hatzoref, Holon, Israel</MenuItem>
                </TextField>
              </FormGroup>
              <FormGroup>
                <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                  {t("campaign_start_date")}
                </FormLabel>
                <TextField
                  type="date"
                  fullWidth
                  {...formik?.getFieldProps("start_at")}
                  sx={{
                    "& .MuiInputBase-root": {
                      background:
                        "linear-gradient(110deg, #151313 0%, #323131 100%)",
                      border: "1px solid #4F4F4F",
                      borderRadius: "8px",
                      color: "#FBC515",
                      fontSize: "16px",

                      "& .MuiOutlinedInput-input": {
                        paddingTop: "14.5px",
                        paddingBottom: "14.5px",
                      },
                    },

                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={Calender} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("enter_here")}
                  inputProps={{ min: minStartDate }} // Set the min attribute to minStartDate
                />
                {formik.touched.start_at && formik.errors.start_at && (
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "x-small",
                      marginLeft: "5px",
                    }}
                  >
                    {formik.errors.start_at}
                  </span>
                )}
              </FormGroup>
              <FormGroup>
                <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                  {t("campaign_end_date")}
                </FormLabel>
                <TextField
                  type="date"
                  fullWidth
                  {...formik?.getFieldProps("closing_at")}
                  sx={{
                    "& .MuiInputBase-root": {
                      background:
                        "linear-gradient(110deg, #151313 0%, #323131 100%)",
                      border: "1px solid #4F4F4F",
                      borderRadius: "8px",
                      color: "#FBC515",
                      fontSize: "16px",

                      "& .MuiOutlinedInput-input": {
                        paddingTop: "14.5px",
                        paddingBottom: "14.5px",
                      },
                    },

                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={Calender} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("enter_here")}
                  inputProps={{ min: minStartDate }} // Set the min attribute to minStartDate
                />
                {formik.touched.closing_at && formik.errors.closing_at && (
                  <span
                    className="text-danger"
                    style={{
                      fontSize: "x-small",
                      marginLeft: "5px",
                    }}
                  >
                    {formik.errors.closing_at}
                  </span>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                  {t("automatic_calculation")}
                </FormLabel>
                <TextField
                  placeholder="0"
                  type="number"
                  fullWidth
                  value={formik.values.campaign_cost}
                  disabled={true}
                  sx={{
                    "& .MuiInputBase-root": {
                      background:
                        "linear-gradient(110deg, #151313 0%, #323131 100%)",
                      border: "1px solid #4F4F4F",
                      borderRadius: "8px",
                      color: "#FBC515",
                      fontSize: "16px",
                      paddingTop: "1px",
                      paddingBottom: "1.5px",
                      "& .MuiOutlinedInput-input": {
                        paddingTop: "12px",
                        paddingBottom: "14.5px",
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#FBC515",
                      },
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#fff",
                      textAlign: "right",
                      mt: 1,
                    },
                    fontSize: "14px",

                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img alt="" src={autoicon} />
                      </InputAdornment>
                    ),
                  }}
                  helperText="“This is an initial quote only”"
                />
              </FormGroup>
              <FormGroup>
                <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                  {t("attach_video")}
                </FormLabel>
                <Stack
                  direction={"column"}
                  spacing={1}
                  component={"ul"}
                  sx={{
                    padding: "0px",
                    "& li": { color: "#fff", fontSize: "12px" },
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    component={"li"}
                  >
                    <CheckCircleOutlineIcon />{" "}
                    <Box component={"span"}>{t("video_files_only")}</Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    component={"li"}
                  >
                    <CheckCircleOutlineIcon />{" "}
                    <Box component={"span"}>{t("limit_30_seconds")}</Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    component={"li"}
                  >
                    <CheckCircleOutlineIcon />{" "}
                    <Box component={"span"}>{t("video_resolution")}</Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems={"center"}
                    component={"li"}
                  >
                    <CheckCircleOutlineIcon />{" "}
                    <Box component={"span"}>{t("ensure_compliance")}</Box>
                  </Stack>
                </Stack>
              </FormGroup>
              <Box
                sx={{
                  p: 3,
                  borderRadius: "8px",
                  background:
                    "linear-gradient(110deg, rgba(255, 255, 255, 0.03) 0%, rgba(255, 255, 255, 0.01) 100%)",
                  backdropFilter: "blur(10px)",
                  border: "1.5px dashed #FFF",
                  height: "76px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  cursor: "pointer",
                  "& input": {
                    position: "absolute",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    top: "0px",
                    opacity: 0,
                    cursor: "pointer",
                  },
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <img src={UploadIcon} alt="" />
                  <Box sx={{ color: "#D5D5D5", fontSize: "15px" }}>
                    Select file
                  </Box>
                </Box>
                <input
                  type="file"
                  name="video"
                  accept=".mp4, .mov"
                  onChange={onVideoLoad}
                />
              </Box>

              {previewvideo && (
                <video
                  controls
                  width="100%"
                  ref={videoRef}
                  src={previewvideo}
                ></video>
              )}
              {formik.touched.video && formik.errors.video && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "x-small",
                    marginLeft: "5px",
                  }}
                >
                  {formik.errors.video}
                </span>
              )}
              <Box sx={{ pt: 2 }}>
                <CustomButton
                  // onClick={handleClickOpen}
                  btnText={t("upload_ad")}
                  type="submit"
                  // icon={<img src={ArrowLeft} alt="" />}
                  disabled={disabled ? true : false}
                />
              </Box>
            </Stack>
          </form>

          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              "& .MuiPaper-root": {
                background:
                  "linear-gradient(97deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.03) 100%)",
                border: "1px solid rgba(255, 255, 255, 0.15)",
                borderRadius: "16px",
                backdropFilter: "blur(5px)",
                margin: "0px 0px 0px -16px",
              },
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{ position: "absolute", top: "0px", right: "0px" }}
            >
              <img src={close} alt="" />
            </IconButton>
            <Box sx={{ p: 3, textAlign: "center" }}>
              <img src={Logo} alt="" width={97} />
              <Typography
                variant="h4"
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: `"Poppins", sans-serif !important`,
                  mb: 2,
                }}
              >
                {t("thanks")}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: `"Poppins", sans-serif !important`,
                }}
              >
                {t("ad_upload_success")}
              </Typography>
            </Box>
          </Dialog>
        </Box>
      )}
    </>
  );
}
