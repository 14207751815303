import { Box, IconButton, Stack } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../components/commanButton";
import ArrowLeft from "../components/icon/arrow-left.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ResendOTP, VerifyOtp } from "../../api/auth";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import { Loader } from "../components/Loader";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Codeverification = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { phone } = useParams();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(30); // Initial countdown time
  const [resendDisabled, setResendDisabled] = useState(false);
  const billboardId = localStorage.getItem("billboardId");

  const validationSchema = Yup.object({
    otp: Yup.string()
      .required("Please enter the OTP")
      .length(4, "OTP must be 4 digits"),
  });

  /* popup if user freezed */
  const handleClick = () => {
    Swal.fire({
      background: "rgb(0 0 0 / 84%)",
      title:
        "<div class='modalix'>Your account is limited. For more details, please contact local support.</div>",

      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showConfirmButton: false,
      width: "450px",
    });
  };
  const onSubmit = async (values) => {
    try {
      // Call the API to verify the OTP
      setLoading(true);
      const response = await VerifyOtp({
        phone,
        otp: values.otp,
        lng: localStorage.getItem("i18nextLng")
          ? localStorage.getItem("i18nextLng")
          : "en",
      });
      // console.log("<Verification Code> response", response);

      if (response.remote === "success") {
        const { isOnboardingDone, address, active } =
          response?.data?.data?.user;
        const policy = localStorage.getItem("policy");
        if (active) {
          if (isOnboardingDone) {
            if (policy) {
              navigate(`/user/${billboardId}/upload-ad`);
            } else {
              navigate(`/user/dashboard`);
            }
          } else {
            navigate(`/user/personal-information/${address?.country}/${phone}`);
          }
        } else {
          handleClick();
        }
        // console.log(response?.data?.data?.user); // Handle the API response as needed
      }
      if (response?.response.status === 422) {
        alert(Object.values(response?.response?.data));
      }

      // Navigate to the next step or perform other actions
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleResendOtp = async () => {
    try {
      setResendDisabled(true);
      const response = await ResendOTP({
        phone,
        lng: localStorage.getItem("i18nextLng"),
      });
      if (response.remote === "success") {
        toast.success(t("otp_success"));
      }
    } catch (error) {
      toast.error(`${t("otp_error")}:${error}`);
    }
  };

  useEffect(() => {
    if (resendDisabled) {
      const intervalid = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer === 1) {
            setResendDisabled(false); // Enable the Resend button when timer reaches zero
            clearInterval(intervalid); // Stop the interval
            return 30; // Reset the counter
          } else {
            return prevTimer - 1; // Decrease the counter
          }
        });
      }, 1000);
      return () => clearInterval(intervalid); // Cleanup the interval on component unmount
    }
  }, [resendDisabled]);
  return (
    <Box sx={{ p: 3, height: "95%", display: "flex", flexDirection: "column" }}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{ flex: "1 1 auto", height: "100%" }}
            dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={2}
              sx={{ mb: 3 }}
            >
              <IconButton
                sx={{
                  background: "#fff",
                  color: "#000",

                  opacity: 1,
                  "&:hover": { color: "#000", background: "#fff" },
                }}
                onClick={() => navigate(`/`)}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Box
                className="backArrow"
                sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
                component={"span"}
              >
                {t("code_validation")}
              </Box>
            </Stack>
            <Box sx={{ color: "#FBC515", fontSize: "20px" }}>
              {t("enter_otp_message")} +{phone}
            </Box>
            <Box
              className="otpInput"
              sx={{
                mt: 2,
                pl: 0.5,
                // "& input:last-child": {
                //   marginRight: "0px !important",
                //   marginLeft: "0px !important",
                // },
              }}
            >
              <OtpInput
                value={formik.values.otp}
                onChange={(otp) => formik.setFieldValue("otp", otp)}
                numInputs={4}
                inputType={"number"}
                //   renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "23%",
                  height: "54px",
                  border: "1px solid #4F4F4F",
                  background:
                    "linear-gradient(110deg, #151313 0%, #323131 100%)",
                  borderRadius: "8px",
                  color: "#F9C809",
                  fontSize: "20px",
                  // margin: "0px 0px 0px 27px",
                }}
              />
            </Box>
            <Box
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                mt: 2,
                color: "#6A6A6A",
                textAlign: "right",
              }}
            >
              <Link
                href="#!"
                // className="disabled-link"
                style={{
                  color: resendDisabled ? "#6A6A6A" : "#FBC515",
                  fontSize: "16px",
                  fontWeight: "600",
                  textDecoration: "none",
                }}
                onClick={() => {
                  if (!resendDisabled) {
                    handleResendOtp();
                  }
                }}
                // aria-disabled={resendDisabled ? true : false}
              >
                {resendDisabled
                  ? `${t("resend_code_message")} ${resendTimer} ${t("sec")}`
                  : t("resend")}
              </Link>
            </Box>
          </Box>
          <Box>
            {/* <Link to="/user/personal-information"> */}
            <CustomButton
              btnText={t("next")}
              onClick={() => formik.handleSubmit()}
              // icon={<img src={ArrowLeft} alt="" />}
            />
            {/* </Link> */}
          </Box>
        </>
      )}
    </Box>
  );
};
export default Codeverification;
