import React from "react";

import { Box, IconButton, Stack, Typography } from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomButton from "../components/commanButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../components/icon/logo.svg";
import Line from "../components/icon/line.svg";
import ArrowLeft from "../components/icon/arrow-left.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";

const UserAds = () => {
  const { t } = useTranslation();

  const name = localStorage.getItem("billboardName");
  const { Id } = useParams();
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const AdsSliderData = [
    {
      id: 1,
      title: t("ad_performance_tracking"),
      description: t("ad_performance_tracking_description"),
    },
    {
      id: 2,
      title: t("effective_ads_transparent_pricing"),
      description: t("effective_ads_transparent_pricing_description"),
      bottom: "-18px",
      right: "56px",
    },
    {
      id: 3,
      title: t("seamless_ad_experience"),
      description: t("seamless_ad_experience_description"),
      bottom: "-13px",
      right: "0px",
    },
  ];

  return (
    <Box
      dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
      sx={{
        p: 3,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height: "95%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        sx={{ mb: 2.5 }}
      >
        <IconButton
          sx={{
            background: "#fff",
            color: "#000",
            opacity: 1,
            "&:hover": { color: "#000", background: "#fff" },
          }}
          onClick={() => navigate(`/user/${Id}`)}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      </Stack>
      <Box sx={{ flex: "1 1 auto" }}>
        <Box sx={{ textAlign: "center" }}>
          <img src={logo} alt="" style={{ width: "150px" }} />
        </Box>
        <Box
          sx={{
            mt: 5,
            color: "#fff",
            "& .slick-dots li button:before": {
              opacity: 1,
              color: "#BBB",
              fontSize: "12px",
            },
            "& .slick-dots li.slick-active button:before": {
              color: "#F9C809",
              opacity: 1,
            },
          }}
        >
          <Slider {...settings}>
            {AdsSliderData.map((item) => (
              <Box key={item.id}>
                <Box
                  dir={
                    localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"
                  }
                  sx={{
                    "& .MuiTypography-h3": {
                      color: "#fff",
                      position: "relative",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "36px", fontFamily: "Poppins" }}
                  >
                    {item.title}
                    <Box
                      component={"span"}
                      sx={{
                        position: "absolute",
                        right: item.right || "16px",
                        bottom: item.bottom || "30px",
                      }}
                    >
                      <img src={Line} alt="" />
                    </Box>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#FBC515", mt: 2, mb: 2 }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
      <Box>
        <Link to={`/user/${Id}/advertising-policy`}>
          <CustomButton btnText={t("next")} />
        </Link>
      </Box>
    </Box>
  );
};
export default UserAds;
