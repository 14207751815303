import { styled } from "@mui/material";

export const AddTable = styled("div")(() => ({
  "& .table-card": {
    "& th": {
      fontWeight: "400",
      color: "#5A6474",
      textAlign: "left",
      padding: "5px 15px",
    },
    "& td": {
      fontWeight: "400",
      color: "#5A6474",

      padding: "15px 15px",
    },
  },
}));
