import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CustomButton from "../components/commanButton";
import ArrowLeft from "../components/icon/arrow-left.svg";
import userIcon from "../../images/user-square.png";
import Calender from "../../images/calendar-2.png";
import Lock from "../../images/lock.png";
import Globe from "../../images/global-search.png";
import Email from "../../images/sms.png";
import Phone from "../../images/call-calling.png";
import React, { useState, useEffect } from "react";
import location from "../../images/location-1.png";
import { GetSelfUpdate } from "../../api/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RotatingLines } from "react-loader-spinner";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";

const Information = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { phone, country } = useParams();
  const [loading, setLoading] = useState(false);
  const name = localStorage.getItem("billboardName");
  const policy = localStorage.getItem("policy");
  const billboardId = localStorage.getItem("billboardId");

  const validationSchema = Yup.object({
    firstName: Yup.string().required(t("validation.firstName")),
    lastName: Yup.string().required(t("validation.lastName")),
    dob: Yup.string().required(t("validation.dob")),
    city: Yup.string().required(t("validation.city")),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.email")),
  });

  const onSubmit = async (values) => {
    try {
      // console.log("\n\n\n<Information> Submit", values);
      setLoading(true);
      const formData = new FormData();
      // Append each form field to the formData
      formData.append("firstname", values.firstName);
      formData.append("lastname", values.lastName);
      formData.append("dob", values.dob);

      // Append each payment preference seperately
      values?.paymentPreference?.forEach((preference, index) => {
        formData.append(`paymentPreference[${index}]`, preference);
      });

      formData.append("city", values.city);
      formData.append("email", values.email);
      formData.append("role", "GuestUser");
      formData.append("country", country);

      console.log("\n\n\n<Information> Submit", formData);

      // Call the API to update user information
      const response = await GetSelfUpdate(formData);
      if (response?.remote === "success") {
        if (policy) {
          navigate(`/user/${billboardId}/upload-ad`);
        } else {
          navigate(`/user/dashboard`);
        }
      }
      console.log(response); // Handle the API response as needed

      // Navigate to the next step or perform other actions
    } catch (error) {
      console.error("Error updating user information:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dob: "",
      paymentPreference: ["Cash", "Credit Card", "Paypal", "Bank Transfer"],
      city: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   // console.log("\n\n\n<Information> values", formik?.values);
  // });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{ p: 3 }}
          dir={localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={2}
            sx={{ mb: 3 }}
          >
            <IconButton
              sx={{
                background: "#fff",
                color: "#000",
                opacity: 1,
                "&:hover": { color: "#000", background: "#fff" },
              }}
              onClick={() => navigate(`/user/code-verification/${phone}`)}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Box
              className="backArrow"
              sx={{ fontSize: "20px", color: "#fff", fontWeight: "600" }}
              component={"span"}
            >
              {t("personal_info")}
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("first_name")}
              </FormLabel>
              <TextField
                placeholder={t("enter_here")}
                fullWidth
                {...formik.getFieldProps("firstName")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={userIcon} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.firstName && formik?.errors?.firstName && (
                <div style={{ color: "red" }}>{formik?.errors?.firstName}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("last_name")}
              </FormLabel>
              <TextField
                fullWidth
                {...formik.getFieldProps("lastName")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                  // input: {
                  //   "&::placeholder": {
                  //     color: "blue",
                  //   },
                  // },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={userIcon} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("enter_here")}
              />
              {formik?.touched?.lastName && formik?.errors?.lastName && (
                <div style={{ color: "red" }}>{formik?.errors?.lastName}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("date_of_birth")}
              </FormLabel>
              <TextField
                type="date"
                fullWidth
                {...formik.getFieldProps("dob")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",

                    "& .MuiOutlinedInput-input": {
                      paddingTop: "14.5px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Calender} />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("enter_here")}
              />
              {formik?.touched?.dob && formik?.errors?.dob && (
                <div style={{ color: "red" }}>{formik?.errors?.dob}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t("country")} <img src={Lock} alt="" />
              </FormLabel>
              <TextField
                fullWidth
                value={country}
                placeholder={country}
                disabled={true}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",

                    "& .MuiOutlinedInput-input": {
                      paddingTop: "14.5px",
                      paddingBottom: "14.5px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#FBC515",
                    },

                    "& .MuiSelect-iconOutlined": { display: "none" },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Globe} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                      {/* <KeyboardArrowDownIcon sx={{ color: "#D5D5D5" }} /> */}
                    </InputAdornment>
                  ),
                }}
                defaultValue="1"
              >
                {/* <MenuItem value={"1"}>country 1</MenuItem>
                <MenuItem value={"2"}>country 2</MenuItem>
                <MenuItem value={"3"}>country 3</MenuItem> */}
              </TextField>
            </FormGroup>

            <Box>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                }}
              >
                {t("payment_preference")}
              </FormLabel>
              <FormGroup
                sx={{
                  border: "1px solid #4F4F4F",
                  borderRadius: "8px",
                  background:
                    "linear-gradient(110deg, #151313 0%, #323131 100%)",
                  px: 3,
                  py: 1.5,
                  "& .MuiTypography-root": {
                    color: "#FBC515",
                    fontSize: "16px",
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      {...formik.getFieldProps("paymentPreference")}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Cash";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("cash")}
                />
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      defaultChecked
                      {...formik.getFieldProps("paymentPreference")}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Credit Card";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("credit_card")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      {...formik.getFieldProps("paymentPreference")}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Paypal";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("paypal")}
                />
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      defaultChecked
                      {...formik.getFieldProps("paymentPreference")}
                      onChange={(e) => {
                        const checked = e?.target?.checked;
                        const preference = "Bank Transfer";

                        // Add or remove preference based on checkbox state
                        if (checked) {
                          // console.log("\n<Information> Checked if ");
                          formik.setFieldValue("paymentPreference", [
                            ...formik?.values?.paymentPreference,
                            preference,
                          ]);
                        } else {
                          // console.log("\n<Information> Checked else ");
                          formik.setFieldValue(
                            "paymentPreference",
                            formik?.values?.paymentPreference?.filter(
                              (item) => item !== preference
                            )
                          );
                        }
                        // console.log(
                        //   "\n<Information> Checked",
                        //   formik.values.paymentPreference,
                        //   checked
                        // );
                      }}
                      sx={{
                        color: "#FFF",
                        "&.Mui-checked": { color: "#FBC515" },
                      }}
                    />
                  }
                  label={t("bank")}
                />
              </FormGroup>
            </Box>
            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("address")}
              </FormLabel>
              <TextField
                fullWidth
                placeholder={t("address")}
                {...formik.getFieldProps("city")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={location} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.city && formik?.errors?.city && (
                <div style={{ color: "red" }}>{formik?.errors?.city}</div>
              )}
            </FormGroup>

            <FormGroup>
              <FormLabel sx={{ color: "#FBC515", fontSize: "16px", mb: 1 }}>
                {t("email")}
              </FormLabel>
              <TextField
                placeholder={t("enter_here")}
                fullWidth
                {...formik.getFieldProps("email")}
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Email} />
                    </InputAdornment>
                  ),
                }}
              />
              {formik?.touched?.email && formik?.errors?.email && (
                <div style={{ color: "red" }}>{formik?.errors?.email}</div>
              )}
            </FormGroup>
            <FormGroup>
              <FormLabel
                sx={{
                  color: "#FBC515",
                  fontSize: "16px",
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {t("phone_number")} <img src={Lock} alt="" />
              </FormLabel>
              <TextField
                type="number"
                placeholder={"+" + phone}
                // value={phone}
                disabled={true}
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    background:
                      "linear-gradient(110deg, #151313 0%, #323131 100%)",
                    border: "1px solid #4F4F4F",
                    borderRadius: "8px",
                    color: "#FBC515",
                    fontSize: "16px",
                    paddingTop: "1px",
                    paddingBottom: "1.5px",
                    "& .MuiOutlinedInput-input": {
                      paddingTop: "12px",
                      paddingBottom: "14.5px",
                    },
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "#FBC515",
                    },
                  },

                  "& fieldset": {
                    display: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img alt="" src={Phone} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
            {/* <Link to={"/user/advertising-policy"}> */}
            <CustomButton
              btnText={t("next")}
              onClick={() => {
                if (Object.values(formik?.errors) > 0) {
                  console.warn(
                    "\n\n\n<Information> Formik Errors",
                    formik.errors
                  );
                } else {
                  console.log("\n\n\n<Information> Submitting ....");

                  formik.handleSubmit();
                }
              }}
              // icon={<img src={ArrowLeft} alt="" />}
            />
            {/* </Link> */}
          </Stack>
        </Box>
      )}
    </>
  );
};
export default Information;
