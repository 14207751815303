import { styled, TextField, FormLabel } from "@mui/material";

export const Input = styled(TextField)(() => ({
  background: "#F4F8FB",
  border: "1px solid rgba(216, 218, 225, 0.5)",
  borderRadius: "5px",
  boxShadow: "none",
  "& fieldset": {
    display: "none",
  },
}));

export const Label = styled(FormLabel)(() => ({
  color: "rgba(34, 41, 67, 0.8)",
  fontSize: "16px",
  marginBottom: "10px",
}));

export const CustomButton = styled("div")(() => ({
  "& .MuiButton-contained": {
    background: "#3AC4F2",
    color: "#fff",
    textTransform: "capitalize",
    fontSize: "16px",
    boxShadow: "none",
    width: "138px",
    "&:hover": {
      background: "#3AC4F2",
    },
  },
  "& .MuiButton-text": {
    background: " rgba(58, 196, 242, 0.15)",
    color: "#3AC4F2",
    textTransform: "capitalize",
    fontSize: "16px",
    boxShadow: "none",
    width: "138px",
  },
}));
