import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const ProtectedRoute = (props) => {
  const [login, setlogin] = useState("");
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem("token");
    if (!login) {
      navigate("/auth");
    } else {
      setlogin(login);
    }
  }, [navigate]);

  return <div>{login ? <Component /> : ""}</div>;
};

export default ProtectedRoute;
