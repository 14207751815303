import { AuthClient } from "../../../../api/api"

export const getTermsAndConditions = (id) => {
    return AuthClient().get(`/v2/terms-and-conditions`);
}

export const saveTermsAndConditions = (data) => {
    const body = {
        detail : data
    }
    return AuthClient().post(`/v2/terms-and-conditions`,body);
}