import { AuthClient } from "../../../../api/api"

export const getTax = (id) => {
    return AuthClient().get(`/v2/tax/${id}`)
}

export const createTax = (data) => {
    return AuthClient().post(`/v2/tax`, data)
}

export const getAllTax = (limit, page, sortBy, search) => {
    return AuthClient().get(`/v2/tax?limit=${5}&page=${page}&sort=${sortBy}`)
}

export const updateTax = (data,id) => {
    return AuthClient().patch(`/v2/tax/${id}`,data)
}

export const deleteTax = (id) => {
    return AuthClient().delete(`/v2/tax/${id}`)
}