import {
  Box,
  Card,
  CardContent,
  FormLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ArrowDown from "../icon/arrow-circle-right-dwon.svg";
import ArrowCricle from "../icon/arrow-circle-right.svg";
import Global from "../icon/global.svg";
import Local from "../icon/Local.svg";
import Partner from "../icon/Partner.svg";
import React from "react";
import moment from "moment";

// interface IAccordingToogle {
//   toogleValue: boolean;
//   handleToogle: () => void;
// }

const AccordingToggle = ({
  toogleValue,
  handleToogle,
  value,
  adExposure,
  cost,
  billboardAd,
  t,
}) => {
  // console.log("\n\n<AccordingToggle> Value", value, "\tadExposure", adExposure);
  return (
    <>
      <Card
        elevation={0}
        sx={{
          background: "linear-gradient(110deg, #151313 0%, #323131 100%)",
          border: "1px solid #4F4F4F",
          borderRadius: "8px",
        }}
      >
        <CardContent
          sx={{
            paddingBottom: "10px !important",
            paddingTop: "10px !important",
          }}
        >
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Box
              sx={{
                fontSize: "12px",
                color: "#fff",
                fontWeight: 500,
                wordWrap: "break-word",
              }}
            >
              {value?.title ? value?.title : t("ad_name_title")}
            </Box>
            <Box
              className="taskList"
              sx={{
                flex: 1,
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                {toogleValue ? null : (
                  <Box sx={{ color: "#03C173", fontSize: "12px" }}>
                    {adExposure}
                  </Box>
                )}

                <img
                  alt=""
                  src={
                    value?.type === "global"
                      ? Global
                      : value?.type === "partner"
                      ? Partner
                      : Local
                  }
                />

                <IconButton onClick={() => handleToogle()} className="taskBtn">
                  {toogleValue ? (
                    <img alt="" src={ArrowDown} />
                  ) : (
                    <img alt="" src={ArrowCricle} />
                  )}
                </IconButton>
              </Stack>
            </Box>
          </Stack>

          {toogleValue && (
            <>
              {billboardAd && (
                <Box>
                  <Typography
                    variant="caption"
                    sx={{ color: "#03C173", fontSize: "12px" }}
                  >
                    {t("billboard")} - {billboardAd}
                  </Typography>
                </Box>
              )}
              <Box sx={{ borderTop: "1px solid #003E44", my: 1, pt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        background: "rgb(203 201 212 / 0.2)",
                        borderRadius: "8px",
                        py: 1,
                        px: 2,
                        textAlign: "center",
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          color: "#fff",
                          fontWeight: 400,
                        },
                      }}
                    >
                      <FormLabel>{t("start_date")}</FormLabel>
                      <Box
                        sx={{
                          fontSize: "14px",
                          color: "#FBC515",
                          fontWeight: 500,
                        }}
                      >
                        {value?.start_at
                          ? moment(value?.start_at).format("MMM DD YYYY")
                          : t("no_data_available")}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        background: "rgb(203 201 212 / 0.2)",
                        borderRadius: "8px",
                        py: 1,
                        px: 2,
                        textAlign: "center",
                        "& .MuiFormLabel-root": {
                          fontSize: "14px",
                          color: "#fff",
                          fontWeight: 400,
                        },
                      }}
                    >
                      <FormLabel>{t("end_date")}</FormLabel>
                      <Box
                        sx={{
                          fontSize: "14px",
                          color: "#FBC515",
                          fontWeight: 500,
                        }}
                      >
                        {value?.closing_at
                          ? moment(value?.closing_at).format("MMM DD YYYY")
                          : t("no_data_available")}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box sx={{ mt: 1 }}>
                  {cost && (
                    <Stack
                      direction={"row"}
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        color: "#FBC515",
                        fontSize: "14px",
                        fontWeight: "500",
                        mb: 1,
                      }}
                    >
                      <Box component={"span"}>{t("cost")}</Box>
                      <Box component={"span"} sx={{ color: "#fff" }}>
                        {cost}
                      </Box>
                    </Stack>
                  )}
                  {value?.status !== "pending" &&
                    value?.status !== "expired" && (
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          color: "#FBC515",
                          fontSize: "14px",
                          fontWeight: "500",
                          mb: 1,
                        }}
                      >
                        <Box component={"span"}>{t("ad_exposure")}</Box>
                        <Box component={"span"} sx={{ color: "#fff" }}>
                          {adExposure}
                        </Box>
                      </Stack>
                    )}
                </Box>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default AccordingToggle;
