import { Avatar, IconButton, Stack } from "@mui/material";
import userpic from "../../../images/userpic_0.png";
import { Link } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import deleteicon from "../../../images/delete.png";
import iconedit from "../../../images/iconedit.png";
import { baseURLImg } from "../../../api/api";

export const UserIcon = (props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Avatar
        alt="Remy Sharp"
        src={
          props.photo === "img/default.jpeg"
            ? userpic
            : `${baseURLImg}/users/${props.photo}`
        }
        sx={{ width: 24, height: 24 }}
      />{" "}
      {/* userpic */}
      <b className="titletable">
        {props.editPermission ? (
          <Link
            to={`/my-profile/user-edit/${props.id}`}
            state={{ disabled: true }}
            style={{ color: "blue" }}
          >
            {props.name}
          </Link>
        ) : (
          props.name
        )}
      </b>
    </Stack>
  );
};

export const UserTest = (props) => {
  return <span className={props.className}>{props.text}</span>;
};

export const ActionIcon = (props) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton size="small" onClick={props.handleClickOpen}>
        <img src={iconedit} alt="" />
      </IconButton>
    </Stack>
  );
};

export const ActionIconDelete = ({ deletepass }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton size="small">
        <img src={deleteicon} alt="" onClick={deletepass} />
      </IconButton>
    </Stack>
  );
};

export const ActionIconDown = (props) => {
  return (
    <>
      <IconButton size="small">
        <ArrowDownwardIcon sx={{ fontSize: "12px" }} />
      </IconButton>
    </>
  );
};
export const USER_COLUMNS_DATA = [
  {
    id: "1",
    name: <>Name</>,
    key: "firstname",
    sortKey: "fullname",
  },
  {
    id: "2",
    name: "Email",
    key: "email",
    sortKey: "email",
  },
  {
    id: "3",
    name: <>Logged In</>,
    key: "online",
    sortKey: "online",
  },
  {
    id: "4",
    name: <>Role</>,
    key: "role",
    sortKey: "role",
  },
  {
    id: "6",
    name: <>Last Log-In</>,
    key: "lastLogin",
    sortKey: "lastLogin",
  },
  {
    id: "7",
    name: "",
    key: "action",
    sortKey: null,
  },
];

export const BILBOARD_COLUMNS_DATA = [
  {
    id: "1",
    name: <>Billboard Name</>,
    key: "billboardName",
  },
  {
    id: "2",
    name: <>Address</>,
    key: "address",
  },
  {
    id: "3",
    name: <>Status</>,
    key: "status",
  },
  {
    id: "4",
    name: <>ADS</>,
    key: "ads",
  },
  {
    id: "5",
    name: <>Spots</>,
    key: "spot",
  },
  {
    id: "6",
    name: <>Contact Name</>,
    key: "contactName",
  },
  {
    id: "7",
    name: <>Phone Number</>,
    key: "phone",
  },
  {
    id: "8",
    name: <>Installation Date</>,
    key: "installationDate",
  },
];

export const ServicesItemsData = [
  {
    id: "1",
    name: "ID",
    key: "id",
    sortKey: "id",
  },
  {
    id: "2",
    name: "Item Name",
    key: "itemname",
    sortKey: "itemname",
  },
  {
    id: "3",
    name: "Item descriptions",
    key: "descriptions",
    sortKey: "descriptions",
  },
  {
    id: "4",
    name: "Cost",
    key: "cost",
    sortKey: "cost",
  },
  {
    id: "5",
    name: "Date Added",
    key: "dateadd",
    sortKey: "dateadd",
  },
  {
    id: "6",
    name: "Last Updated",
    key: "last_updated",
    sortKey: "last_updated",
  },
  {
    id: "7",
    name: "Options",
    key: "options",
    sortKey: "options",
  },
];

export const paymentData = [
  {
    id: "1",
    name: "Invoice ID",
    key: "_id",
    sortKey: "_id",
  },
  {
    id: "3",
    name: "Partner Name",
    key: "customer.fullname",
    sortKey: "customer.fullname",
  },
  {
    id: "4",
    name: "Employee Name",
    key: "employee.fullname",
  },
  {
    id: "5",
    name: "Billboards",
    key: "billboard.billboardName",
  },
  {
    id: "10",
    name: "Amount",
    key: "amount",
  },
  {
    id: "6",
    name: "Status",
    key: "status",
  },
  {
    id: "7",
    name: "Creation date",
    key: "createdAt",
  },
  {
    id: "8",
    name: "Last Updated",
    key: "updatedAt",
  },
  {
    id: "9",
    name: "Action",
    key: "action",
    sort: false,
  },
];

export const paymentIncomeData = [
  {
    id: "1",
    name: "Invoice ID",
    key: "_id",
    sortKey: "_id",
  },
  {
    id: "3",
    name: "Advertiser Name",
    key: "customer.fullname",
    sortKey: "customer.fullname",
  },
  {
    id: "5",
    name: "Billboards",
    key: "billboard.billboardName",
  },
  {
    id: "6",
    name: "Status",
    key: "status",
  },
  {
    id: "10",
    name: "Amount",
    key: "amount",
  },
  {
    id: "7",
    name: "Creation date",
    key: "createdAt",
  },
  {
    id: "8",
    name: "Last Updated",
    key: "updatedAt",
  },
  {
    id: "9",
    name: "Action",
    key: "action",
    sort: false,
  },
];

export const TaxDataColumn = [
  {
    id: "1",
    name: "ID",
    key: "_id",
  },
  {
    id: "2",
    name: "Tax Name",
    key: "name",
  },
  {
    id: "3",
    name: "Rate (Percent)",
    key: "rate",
  },
  {
    id: "4",
    name: "Options",
    key: "action",
  },
];
