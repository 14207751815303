import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { AddTable } from "./addtable-styled";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Editor from "./rich-editor";
import AdminNoteEditor from "./rich-editor/AdminNoteEditor";
import { CustomButton, Input, Label } from "../../services/services.styled";
import Sidebar from "../../sidebar";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { GetallBillboard } from "../../../../api/auth";
import { useFormik } from "formik";
import {
  createIncomeInVoiceSchema,
  createInVoiceSchema,
} from "./create-edit-validation";
import {
  createInvoice,
  getAllCustomers,
  getAllEmployess,
  getInvoice,
  updateInvoice,
} from "./invoice.api";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import { getAllItem } from "../../services/services-items.api";
import { getAllTax } from "../../general-setting/tax/tax-api";
import { getTermsAndConditions } from "../../general-setting/terms&conditions/termsAndCondition.api";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  discountPersent,
  DISCOUNT_TYPE,
  INVOICE_STATUS,
  INVOICE_STATUS_URL,
  OTHER,
  PAYMENT_MODE,
  INVOICE_TYPE,
} from "./invoice.data";
import useUser from "../../../../utils/hooks/useUser";

const CreateEditAddInvoice = (props) => {
  const [billboardList, setBillboardList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const { billboards } = useUser();

  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [invoiceDate, setInvoiceDate] = useState("");
  const [defaultTaxId, setDefaultTaxId] = useState("");
  const CUSTOMER = props.type === "INCOME" ? "Advertiser" : "Client";
  const icon = <CheckCircleIcon fontSize="small" />;
  const checkedIcon = (
    <CheckCircleIcon sx={{ color: "blue" }} fontSize="small" />
  );

  //states for edit invoice
  const getContent = async () => {
    const promise = getTermsAndConditions();
    const response = await promise;
    const data = response.data;
    if (data.status === "success") {
      setContent(data.data);
      formik.setFieldValue("terms_and_conditions", data.data.detail);
    }
  };

  const getBillboard = async () => {
    let response = await GetallBillboard();
    if (response.remote === "success") {
      let data = response.data.data.docs;

      setBillboardList(
        data
          .filter((bill) => billboards.includes(bill.billboardName))
          .map((bill) => ({
            billboardName: bill.billboardName,
            address: bill.address,
            _id: bill._id,
          }))
          .concat(OTHER)
      );
    }
  };

  const getAllItemList = async (type) => {
    let response = await getAllItem(type);
    if (response.data.status === "success") {
      let data = response.data.data;
      setItemList(data);
    }
  };

  const getAllEmployees = async () => {
    let response = await getAllEmployess();
    if (response.status === 200) {
      let data = response.data.data.docs;
      setEmployeesList(data);
    }
  };

  const getCustomers = async (customer) => {
    let response = await getAllCustomers(customer);
    if (response.status === 200) {
      let data = response.data.data.docs;
      setCustomersList(data);
    }
  };

  const getInvoiceDetails = async (id) => {
    let response = await getInvoice(id);
    if (response.status === 200) {
      let data = response.data;
      setInvoiceDate(data.data.createdAt);
      formik.setValues({
        discount_percentage: data.data.discount_percentage,
        terms_and_conditions: data.data.terms_and_conditions,
        admin_note: data.data.admin_note,
        payment_mode: data.data.payment_mode,
        adjustment: data.data.adjustment,
        discount:
          data.data.discount_type === "NO_DISCOUNT" ? 0 : data.data.discount,
        discount_type: data.data.discount_type,
        status: data.data.status,
        customer: data.data.customer?._id || "",
        billboard: data.data.billboard._id,
        employee: data.data.employee?._id || "",
        invoice_due_date: moment(data.data.invoice_due_date).format(
          "YYYY-MM-DD"
        ),
      });

      setSelectedItems(
        data.data.item_tax.map((item) => ({
          id: item.item,
          item: item.item_name,
          rate: item.item_rate,
          description: item.item_description,
          count: item.qty,
          tax: item.tax_rate,
          tax_id: item.tax,
          tax_name: item.tax_name,
        }))
      );
    } else {
      toast.error("Something went Wrong!");
    }
  };

  const getTaxes = async () => {
    const promise = getAllTax();
    const response = await promise;
    const data = response.data;
    if (data.status === "success") {
      setTaxList(data.data);
      const taxObject = data.data.find((item) => item.rate === 0);
      setDefaultTaxId(taxObject?._id);
    }
  };

  const handleRemoveItem = (id) => {
    const filter = selectedItems.filter((item) => {
      return item.id !== id;
    });
    setSelectedItems(filter);
  };

  const handleItemCountChange = (id, value) => {
    if (value < 1) {
      return;
    }
    const updatedItems = selectedItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          count: parseInt(value),
        };
      }
      return item;
    });
    setSelectedItems(updatedItems);
    formik.setFieldValue(
      "items",
      updatedItems.map((item) => {
        return {
          item: item.id,
          tax: item.tax_id,
          qty: item.count,
        };
      })
    );
  };

  const addTaxOnItem = (id, value) => {
    if (value < 0) {
      return;
    }
    const updatedItems = selectedItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          tax: value.rate,
          tax_id: value.id,
          tax_name: value.name,
        };
      }
      return item;
    });

    setSelectedItems(updatedItems);

    formik.setFieldValue(
      "items",
      updatedItems.map((item) => {
        return {
          item: item.id,
          tax: item.tax_id,
          qty: item.count,
        };
      })
    );
  };

  const addDiscount = (value) => {
    if (value < 0) {
      return;
    } else if (value === "") {
      formik.setFieldValue("discount", 0);
    }
    formik.setFieldValue("discount", value);
  };

  const addAdjestment = (value) => {
    if (value === "") {
      formik.setFieldValue("adjustment", 0);
    }
    formik.setFieldValue("adjustment", value);
  };

  const handleChangeContent = (text) => {
    setContent({ ...content, detail: text });
    formik.setFieldValue("terms_and_conditions", text);
  };

  const ItemType =
    props.type === "INCOME" ? "in_income=true" : "in_expense=true";

  useEffect(() => {
    if (query.get("invoice")) {
      getInvoiceDetails(query.get("invoice"));
    }
    getTaxes();
    getCustomers(CUSTOMER);
    getBillboard();
    getAllEmployees();
    getAllItemList(ItemType);
    getContent();
  }, [query]);

  const formik = useFormik({
    initialValues: {
      customer: "",
      billboard: "",
      employee: "",
      invoice_due_date: "",
      payment_mode: "",
      discount_type: "",
      discount: 0,
      adjustment: 0,
      status: "",
      items: [],
      admin_note: "",
      terms_and_conditions: "",
      discount_percentage: discountPersent[0].value,
      type: props.type,
      total: 0,
    },
    validationSchema:
      props.type === "INCOME" ? createIncomeInVoiceSchema : createInVoiceSchema,
    onSubmit: (values) => {
      if (
        props.type === INVOICE_TYPE.EXPENSE &&
        !(!!values.customer || !!values.employee)
      ) {
        formik.setErrors({
          customer: "Select atleast Partner Or Employee",
          employee: "Select atleast Employee Or Partner",
        });
        return;
      }
      (query.get("invoice")
        ? updateInvoice(query.get("invoice"), values)
        : createInvoice(values)
      )
        .then(({ data }) => {
          toast.success(
            `Invoice ${query.get("invoice") ? "Updated" : "Created"}!`
          );
          formik.setSubmitting(false);
          navigate(
            props.type === "INCOME"
              ? `/my-profile/income-invoice/${
                  Object.values(INVOICE_STATUS_URL).includes(
                    query.get("status")
                  )
                    ? query.get("status")
                    : "awaiting-approval"
                }`
              : `/my-profile/expenses-invoice/${
                  Object.values(INVOICE_STATUS_URL).includes(
                    query.get("status")
                  )
                    ? query.get("status")
                    : "awaiting-approval"
                }`
          );
        })
        .catch(({ res }) => {
          toast.error("SomeThing went Wrong!");
          formik.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "items",
      selectedItems.map((item) => {
        return {
          item: item.id,
          tax: item.tax_id,
          qty: item.count,
        };
      })
    );
  }, [selectedItems]);

  const pureSubTotal = selectedItems.reduce(
    (acc, item) => acc + item.rate * item.count,
    0
  );

  const selectedItemTotal = selectedItems.map((item) => {
    let discount_rate = item.rate;
    let tax = (item.rate * item.tax) / 100;

    if (formik.values.discount_type === "AFTER_TAX") {
      discount_rate = discount_rate + tax;
    }

    let discount = (formik.values.discount * discount_rate) / 100;

    if (!formik.values.discount_percentage) {
      discount =
        pureSubTotal === 0
          ? formik.values.discount * item.rate * item.count
          : (formik.values.discount * item.rate * item.count) / pureSubTotal;
    }

    if (formik.values.discount_type === "BEFORE_TAX") {
      tax = ((item.rate - discount) * item.tax) / 100;
    }

    return {
      ...item,
      calculated_discount: discount.toFixed(2),
      calculated_total: item.rate - discount + tax,
      calculated_tax: tax,
    };
  });

  const ADJUSTMENT =
    formik.values.adjustment < 0
      ? Math.abs(formik.values.adjustment || 0)
      : Math.abs(formik.values.adjustment || 0);

  const TOTAL = selectedItemTotal.reduce(
    (acc, curr) => acc + curr.calculated_total * curr.count,
    0
  );

  const GRAND_TOTAL =
    formik.values.adjustment < 0
      ? (TOTAL * 1 - ADJUSTMENT * 1).toFixed(2)
      : (TOTAL * 1 + ADJUSTMENT * 1).toFixed(2);

  useEffect(() => {
    formik.setFieldValue("total", Number(GRAND_TOTAL));
  }, [GRAND_TOTAL]);

  const subTotalItems =
    formik.values.discount_type === "BEFORE_TAX"
      ? selectedItems
          .reduce((acc, item) => acc + item.rate * item.count, 0)
          .toFixed(2)
      : selectedItems
          .reduce(
            (acc, item) =>
              acc +
              (item.rate * item.count +
                (item.rate * item.count * item.tax) / 100),
            0
          )
          .toFixed(2);

  const handleChangeAdminNote = (text) => {
    formik.setFieldValue("admin_note", text);
  };

  return (
    <Grid item lg={10} xs={12}>
      <Card
        sx={{
          minHeight: "calc((100vw/2 - 307px) * 1.34)",
          boxShadow: "0px 4px 25px rgba(42, 89, 152, 0.05)",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: "20px", fontWeight: "600", mb: 3 }}
            variant="h3"
          >
            {!!query.get("invoice") ? "Edit" : "Create"} Invoice
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>
                    {props.type === "INCOME" ? "Advertiser" : "Partner"} name
                  </Label>

                  <Autocomplete
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    value={
                      customersList
                        .map((option) => ({
                          fullname: option.fullname,
                          email: option.email,
                          id: option._id,
                        }))
                        .find(
                          (customer) => formik.values.customer === customer.id
                        ) || {}
                    }
                    disabled={!!formik.values.employee}
                    onChange={(_, value) => {
                      formik.setFieldValue("customer", value?.id || "");
                      formik.setFieldValue("employee", "");
                    }}
                    options={customersList.map((option) => ({
                      fullname: option.fullname,
                      email: option.email,
                      id: option._id,
                    }))}
                    getOptionLabel={(option) =>
                      option.fullname && option.email
                        ? `${option.fullname} , ${option.email}`
                        : ""
                    }
                    renderOption={(props, option) => {
                      const selected = option.id === formik.values.customer;
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.fullname} , {option.email}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder={`Write or search ${
                          props.type === "INCOME" ? "Advertiser" : "Partner"
                        } name`}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                  <span className="text-danger">{formik.errors.customer}</span>
                </FormGroup>
              </Grid>

              {props.type !== "INCOME" && (
                <Grid item lg={12} xs={12}>
                  <FormGroup>
                    <Label>Employee name</Label>
                    <Autocomplete
                      popupIcon={<KeyboardArrowUpIcon />}
                      size="small"
                      id="free-solo-2-demo"
                      value={
                        employeesList
                          .map((option) => ({
                            fullname: option.fullname,
                            email: option.email,
                            id: option._id,
                          }))
                          .find(
                            (employee) => formik.values.employee === employee.id
                          ) || {}
                      }
                      disabled={!!formik.values.customer}
                      onChange={(_, value) => {
                        formik.setFieldValue("employee", value?.id || "");
                        formik.setFieldValue("customer", "");
                      }}
                      options={employeesList.map((option) => ({
                        fullname: option.fullname,
                        email: option.email,
                        id: option._id,
                      }))}
                      getOptionLabel={(option) =>
                        option.fullname && option.email
                          ? `${option.fullname} , ${option.email}`
                          : ""
                      }
                      renderOption={(props, option) => {
                        const selected = option.id === formik.values.employee;
                        return (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.fullname} , {option.email}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <Input
                          placeholder="Write or search employee name"
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                  <span className="text-danger">{formik.errors.employee}</span>
                </Grid>
              )}
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>Shoof name</Label>
                  <Autocomplete
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    value={
                      billboardList.find(
                        (bill) => bill._id === formik.values.billboard
                      ) || {}
                    }
                    onChange={(_, value) => {
                      formik.setFieldValue("billboard", value?._id || "");
                    }}
                    options={billboardList}
                    getOptionLabel={(option) => option.billboardName || ""}
                    renderOption={(props, option) => {
                      const selected = option._id === formik.values.billboard;
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.billboardName} ({option?.address || ""})
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder="Write or search shoof name"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <span className="text-danger">{formik.errors.billboard}</span>
              </Grid>

              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>Status</Label>
                  <Autocomplete
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    value={formik.values.status || null}
                    onChange={(_, value) => {
                      formik.setFieldValue("status", value);
                    }}
                    options={Object.keys(INVOICE_STATUS)}
                    getOptionLabel={(option) => INVOICE_STATUS[option]}
                    renderOption={(props, option) => {
                      const selected = option === formik.values.status;
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {INVOICE_STATUS[option]}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder="Select Status"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <span className="text-danger">{formik.errors.status}</span>
              </Grid>

              <Grid item lg={6} xs={12}>
                <FormGroup>
                  <Label>Invoice Date</Label>
                  <Input
                    type={"date"}
                    size="small"
                    disabled
                    fullWidth
                    value={
                      !!invoiceDate
                        ? moment(invoiceDate).format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD")
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item lg={6} xs={12}>
                <FormGroup>
                  <Label>Invoice Due Date</Label>
                  <Input
                    name="invoice_due_date"
                    type={"date"}
                    size="small"
                    fullWidth
                    inputProps={{
                      min: moment().format("YYYY-MM-DD"),
                    }}
                    value={formik.values.invoice_due_date}
                    defaultValue={formik.values.invoice_due_date}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    renderInput={(params) => <TextField {...params} />}
                    error={
                      formik.touched.invoice_due_date &&
                      Boolean(formik.errors.invoice_due_date)
                    }
                  />
                </FormGroup>
                <span className="text-danger">
                  {formik.errors.invoice_due_date}
                </span>
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>Payment Mode</Label>
                  <Autocomplete
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    value={formik.values.payment_mode || null}
                    onChange={(_, value) => {
                      formik.setFieldValue("payment_mode", value);
                    }}
                    options={Object.keys(PAYMENT_MODE)}
                    getOptionLabel={(option) => PAYMENT_MODE[option]}
                    renderOption={(props, option) => {
                      const selected = option === formik.values.payment_mode;
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {PAYMENT_MODE[option]}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder="Select payment"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <span className="text-danger">
                  {formik.errors.payment_mode}
                </span>
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>Discount Type</Label>
                  <Autocomplete
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    value={formik.values.discount_type || null}
                    disableClearable
                    onChange={(_, value) => {
                      if (value === "NO_DISCOUNT") {
                        addDiscount(0);
                      }
                      formik.setFieldValue("discount_type", value);
                    }}
                    options={Object.keys(DISCOUNT_TYPE)}
                    getOptionLabel={(option) => DISCOUNT_TYPE[option]}
                    renderOption={(props, option) => {
                      const selected = option === formik.values.discount_type;
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {DISCOUNT_TYPE[option]}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder="Select Discount Type "
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <span className="text-danger">
                  {formik.errors.discount_type}
                </span>
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormGroup>
                  <Label>Add Items</Label>
                  <Autocomplete
                    multiple
                    popupIcon={<KeyboardArrowUpIcon />}
                    size="small"
                    id="free-solo-2-demo"
                    disableClearable
                    value={itemList
                      .filter((item) =>
                        selectedItems.find(
                          (selectedItem) => selectedItem.id === item._id
                        )
                      )
                      .map((option) => ({
                        item: option.item,
                        description: option.description,
                        id: option._id,
                        rate: option.rate * 1,
                      }))}
                    onChange={(_, value) => {
                      setSelectedItems(
                        value.map((option) => ({
                          id: option.id,
                          item: option.item,
                          rate: option.rate,
                          description: option.description,
                          count: 1,
                          tax: 0,
                          tax_id: defaultTaxId,
                          ...(selectedItems.find(
                            (item) => item.id === option.id
                          ) || {}),
                        }))
                      );
                    }}
                    options={itemList.map((option) => ({
                      item: option.item,
                      description: option.description,
                      id: option._id,
                      rate: option.rate,
                    }))}
                    getOptionLabel={(option) => `${option.item}`}
                    renderOption={(props, option) => {
                      const selected = selectedItems.some(
                        (item) => item.id === option.id
                      );
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.item}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <Input
                        placeholder="Select items"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </FormGroup>
                <span className="text-danger">{formik.errors.items}</span>
              </Grid>
              <Grid item lg={12} xs={12}>
                {/* List of items Added in the list  */}

                <AddTable>
                  <table width="100%" className="table-card">
                    {selectedItems.length > 0 && (
                      <thead>
                        <tr>
                          <th width="30%">Item</th>
                          <th width="30%">Description</th>
                          <th>Qty</th>
                          <th>Rate</th>
                          <th>Tax </th>
                          <th></th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {selectedItems.map((item, index) => (
                        <tr bgcolor="#F8F8F8" key={index}>
                          <td>
                            <Input
                              disabled
                              size="small"
                              variant="outlined"
                              style={{
                                width: "100%",
                                resize: "both",
                                overflow: "auto",
                              }}
                              placeholder="Item"
                              value={item?.item}
                            />
                          </td>
                          <td>
                            <Input
                              disabled
                              size="small"
                              fullWidth
                              style={{
                                width: "100%",
                                resize: "both",
                                overflow: "auto",
                              }}
                              placeholder="Description"
                              value={item?.description}
                            />
                          </td>
                          <td>
                            <Input
                              type="number"
                              size="small"
                              placeholder="1"
                              min={1}
                              value={item.count}
                              onChange={(e) =>
                                handleItemCountChange(item.id, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              size="small"
                              placeholder="Rate"
                              type="number"
                              style={{
                                width: "100%",
                                resize: "both",
                                overflow: "auto",
                              }}
                              value={item?.rate}
                              disabled
                            />
                          </td>
                          <td>
                            <Autocomplete
                              sx={{
                                width: "100%",
                                resize: "both",
                                overflow: "auto",
                              }}
                              popupIcon={<KeyboardArrowUpIcon />}
                              size="small"
                              id="free-solo-2-demo"
                              disableClearable
                              value={
                                (taxList.length &&
                                  taxList
                                    .map((option) => ({
                                      id: option._id,
                                      name: option.name,
                                      rate: option.rate,
                                    }))
                                    .find((i) => i.id === item.tax_id)) ||
                                taxList
                                  .map((option) => ({
                                    id: option._id,
                                    name: option.name,
                                    rate: option.rate,
                                  }))
                                  .find((i) => i.rate === 0)
                              }
                              onChange={(_, value) => {
                                addTaxOnItem(item.id, value);
                              }}
                              options={taxList.map((option) => ({
                                id: option._id,
                                name: option.name,
                                rate: option.rate,
                              }))}
                              getOptionLabel={(option) =>
                                `${option.name} ${option.rate}%` || ""
                              }
                              renderInput={(params) => (
                                <Input
                                  placeholder="Select Status"
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>{(item?.rate * item?.count).toFixed(2)}</td>
                          <td>
                            <IconButton
                              onClick={() => {
                                handleRemoveItem(item?.id);
                              }}
                              disableRipple={true}
                            >
                              <HighlightOffIcon sx={{ color: "red" }} />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AddTable>

                <AddTable>
                  <table width="100%" className="table-card">
                    <tbody align="right">
                      <tr>
                        <td colSpan={5}></td>
                        <td>
                          <b>Sub Total</b>
                        </td>
                        <td style={{ width: "150px" }}>
                          <b>{pureSubTotal?.toFixed(2)}</b>
                        </td>
                      </tr>

                      {selectedItemTotal.map((item) =>
                        item.tax !== 0 ? (
                          <tr>
                            {item?.tax_name ? (
                              <>
                                <td colSpan={5}></td>
                                <td style={{ color: "72da72" }} align="right">
                                  <b>
                                    {item?.tax_name} : {item.tax}%
                                  </b>
                                </td>
                                <td>
                                  <b>
                                    {(item.calculated_tax * item.count).toFixed(
                                      2
                                    )}
                                  </b>
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        ) : (
                          ""
                        )
                      )}

                      <tr>
                        <td colSpan={2}></td>

                        <td colSpan={4} align="right">
                          <table>
                            <tr>
                              <td className="py-0 px-2">
                                <b>Discount</b>
                              </td>
                              <td
                                className="p-0"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <Input
                                  type="number"
                                  disabled={
                                    !selectedItems.length ||
                                    formik.values.discount_type ===
                                      "NO_DISCOUNT"
                                      ? true
                                      : false
                                  }
                                  placeholder="0"
                                  fullWidth
                                  style={{
                                    margin: "auto",
                                  }}
                                  size="small"
                                  value={formik.values.discount}
                                  onChange={(e) => addDiscount(e.target.value)}
                                />
                                <Select
                                  disabled={
                                    !selectedItems.length ||
                                    formik.values.discount_type ===
                                      "NO_DISCOUNT"
                                      ? true
                                      : false
                                  }
                                  sx={{
                                    width: "60px",
                                  }}
                                  defaultValue={
                                    formik.values.discount_percentage
                                      ? discountPersent[0].name
                                      : discountPersent[1].name
                                  }
                                  value={formik.values.discount_percentage}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "discount_percentage",
                                      e.target.value
                                    );
                                  }}
                                  renderValue={(selected) => {
                                    return selected ? "%" : "Fixed Amount";
                                  }}
                                >
                                  {discountPersent.map((i) => {
                                    return (
                                      <MenuItem key={i.id} value={i.value}>
                                        {i.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <b>
                            {(formik.values.discount_percentage
                              ? (subTotalItems *
                                  ((formik.values.discount || 0) * 1)) /
                                100
                              : (formik.values.discount || 0) * 1
                            ).toFixed(2)}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>

                        <td colSpan={4} align="right">
                          <table>
                            <tr>
                              <td className="py-0 px-2">
                                <b>Adjustment</b>
                              </td>
                              <td className="p-0">
                                <Input
                                  disabled={!selectedItems.length}
                                  placeholder="0"
                                  fullWidth
                                  type="number"
                                  size="small"
                                  min={1}
                                  value={formik.values.adjustment}
                                  onChange={(e) =>
                                    addAdjestment(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <b>
                            {(formik.values.adjustment * 1 || 0).toFixed(2)}
                          </b>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}></td>
                        <td align="right">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>{GRAND_TOTAL}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </AddTable>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: "20px", fontWeight: "600", mb: 2 }}
                >
                  Terms & Conditionss
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Editor
                    content={content}
                    handleChangeContent={handleChangeContent}
                  />
                </Box>
                <span className="text-danger">
                  {formik.errors.terms_and_conditions}
                </span>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: "20px", fontWeight: "600", mb: 2 }}
                >
                  Admin note
                </Typography>
                <Box>
                  <AdminNoteEditor
                    content={formik.values.admin_note}
                    handleChangeContent={handleChangeAdminNote}
                  />
                </Box>
                <span className="text-danger">{formik.errors.admin_note}</span>
              </Grid>
              <Grid item lg={12} xs={12}>
                <CustomButton>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent="flex-end"
                    sx={{ mt: 5 }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Save
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          props.type === "INCOME"
                            ? `/my-profile/income-invoice/${
                                Object.values(INVOICE_STATUS_URL).includes(
                                  query.get("status")
                                )
                                  ? query.get("status")
                                  : "awaiting-approval"
                              }`
                            : `/my-profile/expenses-invoice/${
                                Object.values(INVOICE_STATUS_URL).includes(
                                  query.get("status")
                                )
                                  ? query.get("status")
                                  : "awaiting-approval"
                              }`
                        )
                      }
                      variant="text"
                    >
                      Cancel
                    </Button>
                  </Stack>
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default CreateEditAddInvoice;
