import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
} from "@mui/material";
import { Invoice } from "./invoice-styled";

import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { useEffect, useRef } from "react";

import shooflogo from "../../../images/shooflogo.png";
import { toast } from "react-toastify";
import moment from "moment";
import {
  DISCOUNT_TYPE,
  INVOICE_TYPE,
  PAYMENT_MODE,
} from "../../settings/invoice/awaiting-approval/invoice.data";
import { data } from "./logoinvoice";

const PageTemplate = (props) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          width: "100%",
          borderTop: "10px solid #086d77",
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          borderBottom: "10px solid #086d77",
          display: "flex",
          direction: "row",
          spacing: 2,
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 2em",
        }}
      >
        <div style={{ color: "#3ac4f2" }}>WWW.SHOOFCOMLTD.COM</div>{" "}
        <div>
          <img height={90} alt="" src={shooflogo} />
        </div>
      </div>
    </>
  );
};

const Invoice2 = ({ invoiceData, handleClose, isopen }) => {
  const handleClick = () => {
    let element = document.querySelector("#invoice-generate") || document.body;
    savePDF(
      element,
      {
        fileName: `${invoiceData?._id?.substr(-5)} Invoice`,
        paperSize: "A4",
        forcePageBreak: ".page-break",
        pageTemplate: PageTemplate,
        repeatHeaders: true,
        scale: 0.45,
        margin: "1cm",
      },
      () => {
        toast.success("Invoice Downloaded!");
        handleClose();
      }
    );
  };

  useEffect(() => {
    if (isopen) {
      setTimeout(() => {
        if (!!invoiceData) {
          handleClick();
        }
      }, 1000);
    }
  }, [isopen]);

  return (
    <Modal open={isopen} onClose={handleClose}>
      <Invoice
        sx={{ mt: 3 }}
        style={{
          bgcolor: "#fff",
          p: "2em",
          pb: 0,
          height: "calc(100% - 50px)",
          width: "calc(100vw - 50px)",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          overflow: "auto",
        }}
      >
        <Container maxWidth="xl">
          <Paper
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              minHeight: 1122,
            }}
            id="invoice-generate"
          >
            <Grid container spacing={2} component="table">
              <Grid item xs={6}>
                <Stack direction={"column"} spacing={1}>
                  <Typography variant="h1">
                    {invoiceData?._id?.substr(-5)}
                  </Typography>
                  <Typography variant="h1">Bill To,</Typography>
                  <Typography>
                    {invoiceData.type === INVOICE_TYPE.INCOME
                      ? "Advertiser"
                      : invoiceData.customer
                      ? "Partner"
                      : "Employee"}{" "}
                    Name:{" "}
                    {(invoiceData.customer || invoiceData.employee)?.fullname}
                  </Typography>
                  <Typography>
                    Shoof Name: {invoiceData.billboard?.billboardName}
                  </Typography>
                  <Typography>
                    Address:{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.street
                    }
                    ,{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.city
                    }
                    ,
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.country
                    }
                    ,{" "}
                    {
                      (invoiceData.customer || invoiceData.employee)?.address
                        ?.pincode
                    }
                  </Typography>
                  <Typography>
                    Phone No :{" "}
                    {(invoiceData.customer || invoiceData.employee)?.phone}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction={"column"} spacing={1} alignItems={"flex-end"}>
                  <Typography variant="h1">
                    Invoice Date:{" "}
                    {moment(invoiceData?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography variant="h1">
                    Due Date:{" "}
                    {moment(invoiceData?.invoice_due_date).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid xs={2} item>
                <Box sx={{ pt: 4 }}>
                  {/* <img alt="" src={shooflogo2} /> */}
                  <img src={`data:image/jpeg;base64,${data}`} alt="" />
                </Box>
              </Grid>
              <Grid xs={10} item>
                <TableContainer sx={{ mb: 3, mt: 3 }}>
                  <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#F1F5F9",
                          "& .MuiTableCell-root": {
                            border: "0px",
                            borderRight: "1px solid #9B9B9B",
                            "&:last-child": {
                              borderRight: "0px",
                            },
                          },
                        }}
                      >
                        <TableCell>item</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Unit Rate</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.item_tax?.map((item) => (
                        <TableRow
                          sx={{
                            "&:nth-child(even)": { background: "#F8F8F8" },
                            "& .MuiTableCell-root": {
                              border: "0px",
                              borderRight: "1px solid #9B9B9B",
                              verticalAlign: "top",
                              "&:last-child": {
                                borderRight: "0px",
                              },
                            },
                          }}
                        >
                          <TableCell>{item.item_name}</TableCell>
                          <TableCell>{item.item_description}</TableCell>
                          <TableCell>{item?.qty}</TableCell>
                          <TableCell>{item?.item_rate}</TableCell>
                          <TableCell>
                            {item.tax_name} : {item.tax_rate}%{" "}
                          </TableCell>
                          <TableCell>
                            {(item?.qty * item?.item_rate).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={4} />
                        <TableCell className="discount">Subtotal</TableCell>
                        <TableCell>
                          <b>{invoiceData?.sub_total?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>

                      {invoiceData.item_tax?.map((item) =>
                        item.tax_rate !== 0 ? (
                          <TableRow
                            sx={{
                              "& .MuiTableCell-root": {
                                border: "0px",
                              },
                            }}
                          >
                            <TableCell colSpan={4} />
                            <TableCell className="discount">
                              {item.tax_name} : {item.tax_rate}%{" "}
                            </TableCell>
                            <TableCell>
                              {(item?.tax_value).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )
                      )}

                      {invoiceData?.discount_type === "NO_DISCOUNT" ? (
                        ""
                      ) : (
                        <TableRow
                          sx={{
                            "& .MuiTableCell-root": {
                              border: "0px",
                            },
                          }}
                        >
                          <TableCell colSpan={4} />
                          <TableCell className="discount">
                            ({DISCOUNT_TYPE[invoiceData?.discount_type]})
                            Discount :{" "}
                            {invoiceData?.discount_percentage
                              ? `${invoiceData?.discount}%`
                              : `${invoiceData?.discount}.00`}
                          </TableCell>
                          <TableCell>
                            <b>{invoiceData?.discount_amount?.toFixed(2)}</b>
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow
                        sx={{
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={4} />
                        <TableCell className="discount">Adjustment</TableCell>
                        <TableCell>
                          <b>{invoiceData?.adjustment?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          background: "#F1F5F9",
                          "& .MuiTableCell-root": {
                            border: "0px",
                          },
                        }}
                      >
                        <TableCell colSpan={2}>
                          Mode of Payment :{" "}
                          {PAYMENT_MODE[invoiceData?.payment_mode]}
                        </TableCell>
                        <TableCell colSpan={2} />
                        <TableCell className="discount">Total</TableCell>
                        <TableCell>
                          <b>{invoiceData.total?.toFixed(2)}</b>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {invoiceData.admin_note !== "<p><br></p>" &&
                invoiceData.admin_note !== "" ? (
                  <div style={{ marginLeft: "1%" }}>
                    <Typography variant="h5">Admin Notes</Typography>
                    <Box
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5A6474",
                        marginBottom: "25px",
                      }}
                    >
                      {invoiceData?.admin_note
                        ?.replace(/<p>/g, "")
                        .replace(/<\/p>/g, "")}
                    </Box>
                  </div>
                ) : (
                  " "
                )}
                {invoiceData.terms_and_conditions !== "<p><br></p>" &&
                invoiceData.terms_and_conditions !== "" ? (
                  <div style={{ marginLeft: "1%" }}>
                    <Typography variant="h5">Terms & Conditions</Typography>
                    <Box
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#5A6474",
                      }}
                    >
                      {invoiceData?.terms_and_conditions
                        ?.replace(/<p>/g, "")
                        .replace(/<\/p>/g, "")}
                    </Box>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Invoice>
    </Modal>
  );
};
export default Invoice2;
