import * as Yup from "yup";

export const uploadVideoSchema = Yup.object({
  billboard: Yup.array().required(),
  type: Yup.string().required(),
  title: Yup.string().required(),
  status: Yup.string().optional(),
  video: Yup.string().required(),
  start_at: Yup.date()
    .required("Campaign Start date is required")
    .typeError("Invalid date format")
    .test("start_at", "Campaign End date should be after the Start date", function (value) {
      const { closing_at } = this.parent;
      if (!value || !closing_at) return true;
      return new Date(value) <= new Date(closing_at);
    }),
  closing_at: Yup.date()
    .required("Campaign End date is required")
    .typeError("Invalid date format")
    .test("closing_at", "Campaign End date should be after the Start date", function (value) {
      const { start_at } = this.parent;
      if (!value || !start_at) return true;
      return new Date(value) >= new Date(start_at);
    }),
  advertiser: Yup.string().required(),
  campaign_cost: Yup.string().required(),
  sales_proposal_cost: Yup.string().optional(),
});
